.swt-fueln-datepicker {
    width: 94px;
    height: 25px;
    margin: 0px 9px 0px 0px;
    text-indent: 7px;
    border-radius: 3px;
    border: 1px solid rgb(187,187,187);
    font-size: 16px;
}

.fueln-map-container {
    height: 600px;
}

.fueln-table-header {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #72afc4;
    width: 125px;
    height: 2rem;
    padding: 0px;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
}

.fueln-table-header-long {
    font-family: Arial, Helvetica, sans-serif;
    background-color:  #72AFC4;
    padding: 0px;
    font-weight: 500;
    width: 250px;
    height: 2rem;
    font-size: 16px;
    white-space: nowrap;
}
  
.fueln-table-cell {
    text-align: center;
    color: #47484A;
    font-size: 16px;
    white-space: nowrap;
}

.swt-fueln-nav-element a {
    color: black;
    width: 200px;
    font-weight: 500;
    padding-top:8px;
    background: white;
    margin-right: 10px;
    border-radius: 5px 5px 0px 0px;
    border-color:rgb(187, 187, 187);
    border-width: 1px;
    border-style: solid;
    border-bottom: 0px;
    text-decoration: none;
    /*box-shadow: inset 0px 0px 5px #c1c1c1;*/
}
  
.swt-fueln-nav-element .active {
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-weight: 450;
    background: #5797B6;
    border-radius: 5px 5px 0px 0px;
    /*box-shadow: inset 0px 0px 5px #c1c1c1;*/
}

#marker {
    background-image: url('https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    }
     
.mapboxgl-popup {
    max-width: 200px;
}