
.idling-wrapper {
  max-width: 1200px;
  min-width: 800px;
  margin: 5% auto;
  padding: 0 5% 0 115px;
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
  box-sizing: border-box;
}

.idling-wrapper.loading {
  opacity: 0.3;
}

.idling-geotab-wrapper{
  max-width: 1200px;
  min-width: 800px;
  margin: 5% auto;
  padding: 0 6%;
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
  box-sizing: border-box;
}

.idling-map-container {
  height: 400px;
  z-index: 0;
}

.idling-detail-map-container {
  height: 250px;
  width: 100%;
  z-index: 0;
}

.idling-incident-map-container {
  width: 450px;
  height: 450px;
}

.idling-holdon{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  width:100%;
  height:100%;
}

.idling-button-wrapper {
  width: 100%;
  font-size: 16px;
  height: 35px;
  display: grid;
  text-align: center;
  grid-template-columns: repeat(4, minmax(140px, .19fr)) auto;
  grid-template-rows: 100%;
}

.idling-button-wrapper a {
  color: black;
  font-weight: 500;
  border: 1px solid #2f2f30;
  border-bottom:  1px solid;
  text-decoration: none;
  padding-top: 8px;
  border-radius: 5px 5px 0px 0px;
  background: #f5f5f5;
}

.idling-button-wrapper .active {
  font-weight: 450;
  background-color: #fff!important;
  border-bottom: none;
}

.idling-nav-empty-tab {
  border-bottom: 1px solid;
}

.idling-explain-text {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.38;
  letter-spacing: 0;
  color: #74787d;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.idling-explain-text-2 {
  opacity: .9;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: .1px;
  color: #47484a;
  margin: 0;
}

.idling-table-info-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
table {
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
}

.table-container {
  max-height: 440px;
  margin: 0 auto;
  overflow: auto;
}

.idling-tab-wrapper {
  max-height: 600px;
  overflow: auto;
  border: 1px solid;
  border-top: none;
  margin-bottom: 50px;
  padding: 3rem 2.5rem;
}

.table-header-container {
  background-color: #72afc4;
  height: 90px;
  position: sticky;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  white-space: pre;
  z-index: 3;
}

.idling-table-header, .idling-table-header-sticky {
  min-width: 70px;
  padding: 0 30px;
}

.idling-table-header:first-child, .idling-table-header-long:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #72afc4;
}

.idling-table-header-sticky {
  position: sticky;
  left: 227.8px;
  z-index: 2;
  background-color: #72afc4;
}
.idling-table-header-long {
  padding: 0 30px;
  min-width: 120px;
}

.filter { 
  width: 135px;
  height: 20px;
  border: 1px solid #828b94;
  border-radius: 6px;
  font-size: 11px;
  color: #74787d;
  margin: 0;
}

.table-searchbar { 
    height: 30px;
    margin-top: 5px;  
}

.idling-table-sort-icons {
  padding-left: 4px;
}

.idling-table-sort-up-arrow {
  padding-bottom: 5px;
}

.idling-table-row {
  background-color: white;
  color: #47484A;
}

.clickable-row:hover {
  color: #259cc4;
  text-decoration: underline;
}
.idling-table-row:nth-child(even) {
  background-color: rgb(245, 245, 245);
}

.idling-table-cell, .idling-table-cell-sticky {
  padding: 0 30px;
  color: #47484A;
  font-size: 14px;
  height: 50px;
  white-space: nowrap;
}

.idling-table-cell-sticky {
  position: sticky;
  left: 227.8px;
  z-index: 2;
  background-color: inherit;
}

.idling-table-cell:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: inherit;
}

.table-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination-button {
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;
}
.pagination-button:disabled {
  cursor:default;
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(16, 16, 16, 0.2);
}

.pagination-button:hover:not([disabled]) {
  border-color: #262627;
  color: #262627;
  transform: scale(1.02);
  transition: all ease-in-out .2s;
}

.pagination-details {
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
}

.idling-no-data-msg {
  font-size: 16px;
  color: #4a4a4a;
  white-space: nowrap;
  padding: 10px 0;
  text-align: center;
}

.idling-table-cell .nolink {
  cursor: auto;
}

.idling-link {
  cursor: pointer;
  color: inherit;
}
.idling-link:hover {
  color: #259cc4;
  text-decoration: underline;
}

/* ********Top Controls CSS************* */

.idling-top-controls-wrapper{
  display: flex;
  justify-content: space-between;
  height: auto;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 3px;
  margin-left: 50px;
  padding: 15px 0 15px 20px;
}

.idling-controls-right {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.idling-controls-left {
  display: flex;
  flex-direction: column;
}

.idling-input-group-wrapper {
  display: flex;
  flex-direction: column;
  width: 250px;
  font-size: 16px;
}
.idling-controls-label {
  padding: 8px 0 2px;
  font-size: 16px;
}

.idling-vehicle-class-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 70%;
}

.idling-datepicker {
  width: 105px;
  height: 33px;
  color: #4a4a4a;
  font-size: 18px;
  letter-spacing: .15px;
  margin-right: 10px;
  padding: 0;
  text-indent: 7px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.idling-datepickers-container {
  display: flex;
}

.idling-datepicker:hover {
  box-shadow: rgb(245 245 245) 2px 2px 4px;
}

.idling-dropdown-group {
  color: #4a4a4a;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 5px;
  height:35px;
  width:250px;
  font-size: 16px;
}

.idling-dropdown-small {
  border: 1px solid rgb(187, 187, 187);
  border-radius: 5px;
  height: 35px;
  width:80px;
  font-size: 16px;
  color: #4a4a4a;
  padding-left: 5px;
}
/* This is for updating styles for controls multi select dropdown. See "https://react-multi-select-component.pages.dev/?path=/story/recipes-theming--page" for reference */
.rmsc {
  --rmsc-main: #4285f4;
  --rmsc-hover: rgba(114, 176, 196, 0.412);
  --rmsc-selected: rgba(114, 176, 196, 0.412);
  --rmsc-border: rgb(187, 187, 187);
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 35px; /* Height */
}

.idling-radio-group-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 2px;
}

/* *********END Top Controls CSS*********** */

.idling-text-detail{
  margin-top: 25px;
  padding: 2px;
  min-width: 45%;
  font-weight: 500;
  color:rgb(20, 20, 20);
  font-size: 16px;
}

.idling-text-flex-wrapper{
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.idling-text-flex-col{
  display: flex;
  flex-direction: column;
}
.idling-header-container {
  display: flex;
  min-height: 100px;
  padding-bottom: 10px;
  vertical-align: top;
  align-items: center;
}

.idling-subHeader-wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 135px;
}

.idling-main-headers{
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue",Arial,sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.17;
  color: #47484a;
  padding-left: 10px;
}
.idling-main-header-sub {
  color: #979a9e;
}

.header-sawatch-logo {
  height: 75px;
  padding-right: 10px;
}

.idling-detail-controller-row {
  display: flex;
  flex-direction: row;
}
.idling-detail-input-group {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.idling-subHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: .1px;
  color: #47484a;
  margin: 20px 0;
}
.idling-detail-graph-wrapper{
  height: 250px;
  width: 50%;
  padding: 5px;
  margin: 5px;
  margin-left: 0;
  padding-left: 0;
}

.idling-detail-map-wrapper{
  position:relative;
  top:30px;
  height: 290px;
  width: 50%;
  padding:5px;
  z-index: 0;
}

.idling-top-panels{
  width: 100%;
  display:flex;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 70px;
}

.idling-stat-box-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 1100px) {
  .idling-stat-box-wrapper {
    flex-direction: column;
    justify-content: space-between;
    row-gap: 25px;
    width: auto;
  }
  .idling-top-controls-wrapper{
    margin-left: 20px;
  }
}

.idling-stat-box {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 18%) 2px 2px 15px;
  margin-right: 10px;
  height: 120px;
}


.idling-stat-box-metric {
  font-size: 32px;
  margin: 0;
  padding-bottom: 5px;
  line-height: 37px;
  text-align: center;
  color: rgb(74, 74, 74);
}

.idling-stat-box-label {
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    color: rgb(116, 120, 125);
}

.idling-detail-top-container{
  display:flex;
  flex-direction: row;
}

.idling-popup-text{
  margin: 1px;
  padding: 1px;
  white-space: nowrap;
}

.idling-detail-controls{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-left: 10px;
}

.idling-hbar-container{
  overflow: scroll;
}

.idling-hbar-label{
  margin-left:25px;
  font-size: 15px;
}

.idling-csv-search-bar{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.idling-searchbar-wrapper {
  display: inline;
  padding: 0;
  margin: 0;
}

.idling-search-box{
  width: 248px;
  height: 33px;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 5px;
  margin: 0;
  padding: 0;
  text-indent: 5px;
  font-size: 16px;
}

.idling-graph-label{
  text-align: center;
  width: 65%;
  font-weight: 600;
  font-size: 16px;  
}
.idling-update-modal{
  text-align: center;
  z-index: -1;
}

.swt-idling-loading{
  width: 40vw;
  justify-content: center;
  width: 100%;
  margin-left: 25%;
  margin-top: 10rem;
}

.idling-summary-table{
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: grey;
}

.idling-detail-btn-container{
  display: flex;
  flex-direction: row;
}

.idling-download-btn {
    width: 165px;
    height: 50px;
    margin-left: 5px;
    background-color: #13908b;
    border: none;
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    letter-spacing: .3px;
    color: #fff;
    cursor: pointer;
    font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", "Arial", "sans-serif";
    transition: all ease-in .2s;
}
.idling-download-btn:hover {
  background-color: #035e5b;
  transition: all ease-in .2s;
}

.idling-dropdown-container{
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.idling-dropdown-container select{
  border: 1px solid rgb(179, 178, 178);
  width: 90%;
  height: 2rem;
  border-radius: .25rem;
  color: rgb(75, 75, 75);
  float: right;
  font-size: 16px;
}

.idling-dropdown-items-wrapper{
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 10px;
  margin-top: 1rem;
  padding: 15px;
  min-height: 5rem;
  /* background-color: rgba(197, 194, 194, 0.308); */
  border-radius: 0.25rem;
  -webkit-box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.26);
}

.idling-dropdown-items-wrapper div{
  margin-top: 1rem;
  color: black;
  border: 1px solid rgba(151, 149, 149, 0.329);
  text-align: left;
  padding: 10px;
  border-radius: .25rem;
  padding: 15px;
  background-color: white;

}

.idling-dropdown-items-wrapper p{
  color: grey;
  font-size:1rem;
  text-align: center;
  width: 100%;position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.idling-modal-close-button{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color:rgba(19, 144, 139);
  color: white;
  margin: 0px;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 3px;
  min-height: 30px;
  min-width: 90px;
  cursor: pointer;
}

.idling-modal-clear-button{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color: white;
  color: black;
  margin: 15px 0px 0px 0px;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 3px;
  min-height: 30px;
  min-width: 90px;
  cursor: pointer;
}

.idling-additional-fields-button{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color:rgba(19, 144, 139);
  color: white;
  border: 1px solid;
  border-color: rgb(187, 187, 187);
  border-radius: 3px;
  min-height: 30px;
  min-width: 90px;
  margin-top: 10px;
  cursor: pointer;
  overflow-y: hidden;
  overflow-x: hidden;
}

.idling-landing-container{
  justify-content: center;
  height: 600px;
  width: 400px;
  margin:0 auto
}

.idling-landing-btn{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background-color:rgba(19, 144, 139);
  color: white;
  border: 1px solid;
  border-radius: 3px;
  border-color: rgb(187, 187, 187);
  height: 35px;
  width: 100px;
  cursor: pointer;
  margin-top:55px;
  margin-left:150px;
}

.idling-landing-logo{
  width: 250px;
  margin-left:75px;
  margin-top: 75px;
  opacity: .5;
}

.idling-landing-text{
  font-family: Arial, Helvetica, sans-serif;
  margin-top:25px;
  width:500px;
  text-align: left;
  font-size: 16px;
}

.idling-return-btn{
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue",Arial,sans-serif;
  width: 115px;
  height: 35px;
  padding: 0 10px;
  margin-top: 10px;
  margin-right: 15px;
  border: .7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
}

.idling-return-btn:hover {
  border-color: #262627;
  color: #262627;
}

.idling-table-cell-highlight{
  color: red;
}

.ReactModal__Overlay--after-open{
  z-index:1001;
}

.idling-above-80-table{
  margin-top:20px;
  z-index:10000;
}

.idling-incident-content-text {
    margin-left: 50px;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    letter-spacing: .3px;
    color: #47484a;
    border: 1px solid rgb(187, 187, 187);
    border-radius: 3px;
    padding: 15px;
    height: 50%;
}

.idling-map-wrapper {
  position: relative;
}

.idling-map-satellite-control {
  position: absolute;
  height: 30px;
  width: max-content;
  bottom: 10px;
  right: 10px;
  background-color: white;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 2px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  column-gap: 5px;
  white-space: pre;
}

.idling-incident-text-light {
  font-weight: 500;
}

@media (min-width: 1300px) {
  .idling-wrapper {
    padding: 0 4.5%;
  }

  .idling-geotab-wrapper {
    padding: 0 3.5%;
  }
}