@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff2)
      format("woff2"),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff)
      format("woff"),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.ttf)
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

.nrel-UniDashboard .nrel-dashboard-app h1 {
  font-size: 1.5rem;
}

.nrel-UniDashboard {
  font-size: 14px;
  font-size: 1.4rem;
  font-size: 62.5%;
  height: 100%;
}

.nrel-UniDashboard .nrel-dash-landing {
  display: grid;
  position: relative;
  grid-template-areas:
    "title title"
    "info stats"
    "tabs tabs";
  grid-template-rows: 8rem 10rem 73%;
  grid-template-columns: 30% 70%;
  /* height: 65rem; */
  width: 90%;
  overflow: hidden;
  min-width: 981px;
  padding-bottom: 4rem;
  margin-left: 7rem;
}

.nrel-UniDashboard .nrel-dash-landing .nrel-dash-title {
  font-style: normal;
  font-stretch: normal;
  color: #47484a;
  margin-top: 3.6rem;
  margin-left: 0rem;
  margin-bottom: 0rem;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
}

.nrel-UniDashboard .nrel-dash-title2 {
  color: grey;
  margin-left: 0rem;
  margin-top: 0.6rem;
  display: inline-block;
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
  width: 12rem;
}

.nrel-UniDashboard .nrel-pwrd-by-image {
  position: relative;
  margin-left: 0rem;
  width: 2rem;
  left: 5.5rem;
}

.nrel-UniDashboard .nrel-nrel-logo {
  width: 7rem;
  position: absolute;
  right: 10rem;
  top: 5.4rem;
}

.nrel-UniDashboard .nrel-sawatch-logo {
  width: 7rem;
  position: absolute;
  right: 2rem;
  top: 4rem;
}

.nrel-UniDashboard .nrel-dash-user-info-wrpr {
  grid-area: info;
  background-color: rgb(223, 230, 237);
  width: 21rem;
  height: 5.5rem;
  margin-top: 1.2rem;
  margin-left: 0rem;
  border-radius: 1rem;
  padding: 1rem;
}

.nrel-UniDashboard .nrel-fleet,
.nrel-UniDashboard .nrel-telematics,
.nrel-UniDashboard .nrel-observation,
.nrel-UniDashboard .nrel-total-vehicles,
.nrel-UniDashboard .nrel-analyzed {
  margin: 0rem;
  margin-top: 0.1rem;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #47484a;
}
.nrel-UniDashboard .nrel-fleet {
  margin-top: 0rem;
}

.nrel-UniDashboard .nrel-info-value {
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  color: #74787d;
}
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-value {
  font-size: 0.9rem;
}
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-indicator-arrow {
  width: 0.4rem;
}
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-tco,
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-ghg,
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-fuel,
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-vcls {
  font-size: 0.8rem;
}

.nrel-UniDashboard .nrel-overallStats-wrpr {
  margin-left: 15%;
  display: grid;
  grid-area: stats;
  width: 25rem;
  height: auto;
  padding: 0.25rem;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  margin-top: 1.2rem;
  box-shadow: 5px 5px 3px grey;
}

.nrel-UniDashboard .nrel-sensitivity-analysis-controls .nrel-overallStats-wrpr {
  width: 94%;
  height: fit-content;
  margin-left: 0.25rem;
  margin-top: .3rem;
  padding: 0.25rem;
  background-color: white;
}
.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-stats-wrapper {
  display: grid;
  grid-template-columns: 30% 25% 25% 20%;
}

.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-overallStats-wrpr
  .nrel-overallStats {
  margin: auto;
  font-size: 1rem;
}

.nrel-UniDashboard .nrel-nav-stats-wrapper {
  grid-area: stats;
  position: absolute;
  right: 4rem;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-stats-wrapper {
  display: grid;
  grid-template-columns: 30% 27% 25% 18%;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-indicator-arrow {
  width: 0.6rem;
}
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-overallStats {
  margin: 0rem;
  color: rgb(82, 80, 80);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.1px;
  margin-left: 1.6rem;
}
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-tco,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-ghg,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-fuel,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-vcls {
  margin: auto;
  display: inline-block;
  /* margin-left: 6%; */
  text-align: center;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 1rem;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-tco .nrel-value#inc,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-ghg .nrel-value#inc,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-fuel .nrel-value#inc {
  color: red;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-tco .nrel-value#dec,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-ghg .nrel-value#dec,
.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-fuel .nrel-value#dec {
  color: green;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-value#vcl {
  color: black;
  font-weight: 500;
}

.nrel-UniDashboard .nrel-overallStats-wrpr .nrel-value {
  display: inline-block;
  color: grey;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.nrel-UniDashboard .nrel-sensitivity-analysis-controls .nrel-value {
  display: inline-block;
  color: grey;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.nrel-UniDashboard .nrel-unit {
  font-size: 0.4rem;
  color: grey;
}

.nrel-UniDashboard
  .nrel-sensitivity-analysis-controls
  .nrel-value
  .nrel-overallStats-wrpr
  .nrel-indicator-arrow {
  width: 0.8rem;
}
/* TABS */
.nrel-UniDashboard .nrel-tab-wrapper {
  display: grid;
  grid-area: tabs;
  margin-top: 2.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 5%;
  width: 100%;
}

.nrel-UniDashboard .tab-content {
  background-color: white;
  min-height: 44rem;
  height: fit-content;
  border: 1px solid grey;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 3px grey;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.nrel-UniDashboard .tab-list {
  margin-left: 0.5;
  margin-right: 10;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
}

.nrel-UniDashboard .tab-list-item {
  color: rgb(80, 80, 80);
  background-color: rgb(223, 230, 237);
  font-size: 0.72rem;
  display: inline-block;
  list-style: none;
  width: auto;
  min-width: 4rem;
  border-left: 0.5px solid rgb(114, 112, 112);
  border-right: 0.5px solid rgb(114, 112, 112);
  border-top: 0.5px solid rgb(114, 112, 112);
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  border-radius: 9px 9px 0px 0px;
  text-align: center;
  box-shadow: 5px 0px 3px grey;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid rgb(136, 133, 133);
}
.nrel-UniDashboard .tab-list-item#Glossary,
.nrel-UniDashboard .tab-list-item#About,
.nrel-UniDashboard .tab-list-item#Settings {
  background-color: rgb(184, 180, 180);
  color: white;
}
.nrel-UniDashboard .css-1hwfws3 {
  overflow: scroll;
  max-height: 90px;
}
.nrel-UniDashboard .tab-list-active {
  background-color: white !important;
  color: rgb(71, 72, 74) !important;
  border: solid rgb(85, 84, 84);
  border-radius: 9px 9px 0px 0px;
  border-width: 1px 1px 0 1px;
}

.nrel-UniDashboard .nrel-viewAssumptions {
  position: absolute;
  right: 14%;
  top: 9.5rem;
  height: 40px;
  width: 165px;
  color: rgb(116, 120, 125);
  font-size: 1rem;
  border-radius: 0.1rem;
  border: 1px solid rgb(116, 120, 125);
  background-color: white;
  border-radius: 1rem;
}
.nrel-UniDashboard .nrel-ezevRecs,
.nrel-UniDashboard .nrel-mapTab {
  min-height: 50rem;
}

/* TABLE */
.nrel-UniDashboard .nrel-ezevRecs {
  position: relative;
  display: grid;
  grid-template-rows: 16% 10% 78%;
  grid-template-columns: 85% 15%;
  grid-template-areas:
    "title title"
    "buttons csv"
    "table table";
  width: 99%;
  margin-right: 5%;
  min-height: 30rem;
  height: 48rem;
}

.nrel-UniDashboard .nrel-ezevRecs .title-container{
  grid-area: title;
}
.nrel-UniDashboard .nrel-ezevRecs .title-container p{
color: grey;
margin-left: 2rem;
font-size: .75rem;
}

.nrel-UniDashboard .nrel-recsTable-wrpr {
  grid-area: table;
  overflow: scroll;
  max-height: 33.5rem;
  margin-left: 2rem;
  margin-top: 0.5rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
}



.nrel-UniDashboard .nrel-recsTable-wrpr .nrel-loading-background {
  background-color: white;
}

.nrel-UniDashboard .nrel-recsTable-wrpr .nrel-loading-background .nrel-loading {
  margin-top: 5%;
}
.nrel-UniDashboard .nrel-ezevRecs .nrel-table {
  max-height: 28rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
  width:100%;
  border-collapse: collapse;
  border-radius: 4px 4px 0 0;
}

.nrel-UniDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-both,
.nrel-UniDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-desc,
.nrel-UniDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-asc {
  width: 7px;
}

.nrel-UniDashboard .nrel-table-header .YMM,
.nrel-UniDashboard .nrel-table-header .AssetID,
.nrel-UniDashboard .nrel-table-header .Year,
.nrel-UniDashboard .nrel-table-header .Make,
.nrel-UniDashboard .nrel-table-header .Model,
.nrel-UniDashboard .nrel-table-header .ReasonOfFailure,
.nrel-UniDashboard .nrel-table-header .ezEVScore,
.nrel-UniDashboard .nrel-table-header .Location,
.nrel-UniDashboard .nrel-table-header .NumbOfEVs,
.nrel-UniDashboard .nrel-table-header .MaxKW,
.nrel-UniDashboard .nrel-table-header .PeakTime,
.nrel-UniDashboard .nrel-table-header .Class,
.nrel-UniDashboard .nrel-table-header .Price,
.nrel-UniDashboard .nrel-table-header .BatteryCap,
.nrel-UniDashboard .nrel-table-header .MPG,
.nrel-UniDashboard .nrel-table-header .BestInClassOverall,
.nrel-UniDashboard .nrel-table-header .BestInClassYMM,
.nrel-UniDashboard .nrel-table-header .BestInClassReasonFailure,
.nrel-UniDashboard .nrel-table-header .ezEVRec,
.nrel-UniDashboard .nrel-table-header .lvl2ChargingHrs {
  background-color: #72afc4;
  color: black;
  font-size: 0.8rem;
  font-weight: 450;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background-position: right 5px center;
  position: sticky;
  top: 0rem;
}
.nrel-UniDashboard .swt-sort-arrows {
  margin-left: 5%;
}

.nrel-UniDashboard .nrel-table-header .asset_id {
  width: 20%;
}
.nrel-UniDashboard .nrel-table-header .Year {
  width: 8%;
}
.nrel-UniDashboard .nrel-table-header .Make {
  width: 10%;
}
.nrel-UniDashboard .nrel-table-header .Model {
  width: 10%;
}
.nrel-UniDashboard .nrel-table-header .ezEVRec {
  width: 25%;
}
.nrel-UniDashboard .nrel-table-header .ezEVScore {
  width: 10%;
}
.nrel-UniDashboard .nrel-table-header .ReasonOfFailure {
  width: 20%;
}

.nrel-UniDashboard .nrel-table-header .Location {
  width: 22%;
}

.nrel-UniDashboard .nrel-table-header .NumbOfEVs {
  width: 5%;
}

/* end */

.nrel-UniDashboard .nrel-download-btn {
  grid-area: csv;
  margin-left: 68%;
  margin-top: 3%;
  background-color: rgba(19, 144, 139);
  color: white;
  border-radius: 0rem;
  height: 35px;
  width: 120px;
}

.nrel-UniDashboard .nrel-location-vehicle-wrapper .nrel-download-btn p {
  background-color: rgba(19, 144, 139);
  border-radius: 0.25rem;
  color: white;
  border-radius: 0rem;
  height: auto;
  padding-top: 5px;
  padding-bottom: 8px;
  width: 105px;
  margin-top: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right:0;
}

.nrel-UniDashboard .nrel-download-btn p {
  padding-left: 16px;
  margin-bottom: 20px;
  margin-top: 0.6rem;
  text-decoration: none;
  font-size: 0.8rem;
}

.nrel-UniDashboard .nrel-download-btn#ezevRecs {
  margin-left: 1.1rem;
  margin-top: 2.3rem;
  margin-bottom: 0rem;
}

.nrel-UniDashboard .nrel-download-btn#Settings {
  margin-left: auto;
  margin-right: 0.5rem;
}
.nrel-UniDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#ice {
  grid-area: ice;
  border: 1px solid grey;
}
.nrel-UniDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#bev {
  margin-top: 0.5rem;
  grid-area: bev;
  border: 1px solid grey;
}

.nrel-UniDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#phev {
  grid-area: phev;
  border: 1px solid grey;
}

.nrel-UniDashboard .nrel-ezevRecs-headers {
  text-align: left;
  grid-area: title;
  margin: 0rem;
  margin-top: 1.3rem;
  margin-left: 2rem;
  color: rgb(109, 110, 112);
  font-weight: 500;
  font-size: 1.3rem;
}

.nrel-UniDashboard .nrel-map-controller {
  position: relative;
  margin-top: 0rem;
}

.nrel-UniDashboard .nrel-title-container {
  display: grid;
  grid-area: title;
  position: relative;
}

.nrel-UniDashboard .nrel-link {
  position: absolute;
  top: 5.5rem;
  float: right;
  width: 6rem;
}

.nrel-UniDashboard .nrel-assumptions-page {
  display: grid;
  grid-template-areas:
    "title csv"
    "description description"
    "assumptions assumptions"
    "bev bev"
    "phev phev"
    "ice ice";
  border-radius: 10px;
  min-height: 40rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.nrel-UniDashboard .nrel-divider {
  width: 21rem;
  height: 0;
  border: solid 0.25px rgba(128, 128, 128, 0.397);
}

.nrel-UniDashboard .nrel-assumption {
  margin: 0.25rem;
  font-weight: 500;
  font-size: 0.8rem;
}

.nrel-UniDashboard .nrel-assumptions-title {
  margin: 0rem;
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: grey;
  height: fit-content;
  text-align: left;
}

.nrel-UniDashboard .nrel-assumptions-description {
  padding-left: 0.5rem;
}

.nrel-UniDashboard .nrel-table-text-right-aligned {
  text-align: right;
  color: grey;
  font-weight: 250;
}

.nrel-UniDashboard .nrel-information-card {
  min-width: 40rem;
  background-color: rgb(223, 230, 237);
  height: 7rem;
  border-radius: 0.5rem;
  padding: 2rem;
  padding-bottom: 0rem;
  margin-top: 0.05rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  grid-area: assumptions;
}
.nrel-UniDashboard .nrel-information-card .nrel-card-content-l {
  float: left;
  width: 50%;
}
.nrel-UniDashboard .nrel-information-card .nrel-card-content-r {
  float: right;
  width: 50%;
  /* margin-right: 1%; */
}
.nrel-UniDashboard .nrel-information-card .nrel-card-content-l .nrel-divider,
.nrel-UniDashboard .nrel-information-card .nrel-card-content-r .nrel-divider {
  width: 75%;
}
.nrel-UniDashboard .nrel-assumptions-page .nrel-table {
  width: 100%;
  border-collapse: collapse;
  border-width: 0px;
  margin-bottom: 1rem;
}

.nrel-UniDashboard .nrel-assumptions-page .nrel-assumption-header {
  background-color: #72afc4;
  margin: 0rem;
  padding: 0.5rem;
  font-weight: 500;
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  position: sticky;
  top: 0px;
}

.nrel-UniDashboard .nrel-table td {
  display: inline-block;
  display: table-cell;
  flex: 1 0;
  white-space: normal;
  padding: 15px 5px 7px;
  transition: 0.3s ease;
  font-size: 14px;
  overflow: hidden;
  transition-property: width, min-width, padding, opacity;
}

.nrel-table-cell-BatteryCap {
  text-align: center !important;
}

.nrel-table-cell-Price {
  text-align: center !important;
}

.nrel-table-cell-MPG {
  text-align: center !important;
}

.nrel-UniDashboard .nrel-assumption-table-wrpr .nrel-table td {
  text-align: center;
}

.nrel-UniDashboard .nrel-table tr:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}

.nrel-ezevRecs .nrel-table td:nth-child(5) {
  background-color: rgba(221, 236, 236, 0.55);
}

.nrel-UniDashboard .nrel-assumptions-page .Model {
  width: 35%;
}

.nrel-UniDashboard .nrel-assumptions-page .nrel-table {
  max-height: 20rem;
  overflow: scroll;
}

.nrel-UniDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr {
  max-height: 50rem;
  overflow: scroll;
  margin-bottom: 1%;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 1rem;
}

.nrel-UniDashboard .nrel-assumption-table-wrpr .Model,
.nrel-UniDashboard .nrel-assumption-table-wrpr .Class,
.nrel-UniDashboard .nrel-assumption-table-wrpr .Price,
.nrel-UniDashboard .nrel-assumption-table-wrpr .BatteryCap,
.nrel-UniDashboard .nrel-assumption-table-wrpr .MPG {
  position: sticky;
  top: 31px;
}

.nrel-UniDashboard .nrel-sensitivity-analysis-wrpr {
  display: grid;
  grid-template-areas: "chart control";
  grid-template-columns: 66% 34%;
  width: 100%;
  height: 100%;
  min-height: 40rem;
  overflow: hidden;
}

.nrel-UniDashboard .nrel-sensitivity-analysis-chart {
  display: grid;
  grid-area: chart;
  border: 1px solid grey;
  border-radius: 4;
  margin: 10px;
  /* margin-right: 5px; */
  min-height: 4rem;
  position: relative;
  width: 98%;
}

.nrel-UniDashboard .nrel-sensitivity-analysis-controls {
  grid-area: control;
  background-color: rgba(128, 128, 128, 0.384);
  border: 1px solid grey;
  border-radius: 4px;
  margin: 10px;
  /* margin-left: 5px; */
  min-height: 4rem;
  width: 96%;
}

.nrel-UniDashboard .nrel-slider-wrapper {
  border: 0.5px solid rgba(134, 133, 133, 0.781);
  border-radius: 8px;
  background-color: rgb(250, 250, 250);
  -webkit-box-shadow: inset 6px 4px 16px -7px rgba(143, 140, 143, 0.17);
  -moz-box-shadow: inset 6px 4px 16px -7px rgba(143, 140, 143, 0.17);
  box-shadow: inset 6px 4px 16px -7px rgba(143, 140, 143, 0.17);
  margin: 5px;
  padding-bottom: 12px;
  /* padding-top: 12px; */
}

.nrel-UniDashboard .nrel-slide {
  padding: 0.5px;
  width: 96%;
  font-size: 12px;
}

.nrel-slide-explan {
  font-size: 13px;
  padding-bottom: 10px;
}

.nrel-UniDashboard .nrel-chargingNeeds {
  font-weight: 500;
  font-size: 16px;
}

.nrel-UniDashboard .nrel-slide p {
  padding-left: 15px;
}
.nrel-UniDashboard .nrel-slide#chargingNeeds span {
  font-size: 0.75rem;
}

.nrel-UniDashboard .nrel-slide input {
  margin-left: 10%;
  width: 80%;
}

.nrel-UniDashboard .nrel-reset-btn {
  grid-area: reset;
  background-color: rgb(253, 253, 253);
  margin: auto;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 8px;
  min-width: 5rem;
}

.nrel-UniDashboard .nrel-update-btn {
  grid-area: update;
  background-color: white;
  margin: auto;
  min-width: 5rem;
  border-radius: 4px;
  border: 1px solid #979a9e;
  color: #979a9e;
  padding: 8px;
}

.nrel-UniDashboard .nrel-update-btn.changes-to-process {
  background-color: rgba(138, 212, 69, 0.8);
  border-color: #47484a;
  color: #47484a;
}

.nrel-UniDashboard .nrel-sliders {
  background-color: white;
  margin: 0.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.322);
  height: 65%;
  padding-top: 10px;
  -webkit-box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
  -moz-box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
  box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
}

.nrel-UniDashboard .nrel-sliders-error {
  background-color: white;
  color: rgb(82, 80, 80);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.1px;
  margin: 0.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(128, 128, 128, 0.322);
  padding: 15px;
  -webkit-box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
  -moz-box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
  box-shadow: 4px 2px 8px -4px rgba(0, 0, 0, 0.77);
}

.nrel-sa-button-wrpr {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 50% 50%;
  grid-template-areas: "reset update";
}

/* ._step datalist option{
  display: block;
  position: relative;
  color: red;
  padding: 0 10px;
  background-color: green;
  height: 15rem;
  width: 10rem;
}

._step:-ms-ticks-before{
color: red;
}
._step::-ms-ticks-after{
  color: red;
} */

.nrel-slider-wrapper label {
  margin-left: 5%;
  font-size: 11px;
}
.nrel-UniDashboard .sliderticks {
  display: flex;
  justify-content: space-between;
}

.nrel-UniDashboard .sliderticks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 1px;
  background: #d3d3d3;
  height: 10px;
  line-height: 40px;
  margin: 0 0 20px 0;
}

.nrel-UniDashboard .nrel-loading-background {
  width: 100%;
  background-color: rgb(243, 242, 242);
}

.nrel-UniDashboard .nrel-loading {
  margin-top: 16%;
  margin-left: 27%;
}

.nrel-UniDashboard .nrel-processingText {
  margin-left: 30%;
  margin-top: -1rem;
  font-size: 1.4rem;
  color: grey;
}

.nrel-UniDashboard .nrel-bold {
  font-weight: bold;
}

.nrel-UniDashboard .nrel-charge-text-wrapper {
  background-color: rgb(241, 237, 237);
  border: 1px solid rgb(231, 229, 231);
  border-radius: 0.25rem;
  margin: 0.25rem 2rem 0.25rem 2rem;
  text-align: left;
  height: auto;
}

.nrel-UniDashboard .nrel-charging-text {
  color: grey;
  margin-left: 2rem;
  font-size: 0.8rem;
  margin: 0rem;
  padding-top: 0.25rem;
}

.nrel-UniDashboard .nrel-charging-text .nrel-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.nrel-UniDashboard .nrel-charging-graph {
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin: 1%;
  width: 98%;
  padding-bottom: 0.25rem;
  height: 96%;
  position: relative;
  overflow: hidden;
}

.nrel-UniDashboard .nrel-graph-controls-monthly{
margin-bottom: 0rem;
}

.nrel-UniDashboard .nrel-graph-wrapper#monthly {
  height: 49.5rem;
}

.nrel-UniDashboard .nrel-graph-wrapper#daily {
  height: 49.5rem;
}

.nrel-UniDashboard .nrel-graph-explanation {
  margin-top: 1.5rem;
}

.nrel-graph-legend {
  width: 80%;
  margin-left: 10%;
}
.nrel-graph-legend#daily {
  display: grid;
  grid-template-columns: 50% 50%;
}
.nrel-graph-legend .legend-item {
  width: fit-content;
  margin: auto;
}

.nrel-UniDashboard .nrel-graph-back-button {
  background-color: white;
  border: 1px solid grey;
  padding: 5px;
  max-width: 10rem;
  border-radius: 0.25rem;
  color: rgb(82, 81, 81);
  margin: 5px 8px 0px 0px;
}

.nrel-UniDashboard .nrel-noVcls-message {
  color: grey;
  text-decoration: underline;
  font-size: 1rem;
  margin-top: 5%;
  border: 1px solid rgba(128, 128, 128, 0.671);
  position: absolute;
  padding: 3rem;
  background-color: white;
  border-radius: 0.25rem;
  width: 35rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.nrel-UniDashboard .nrel-charging-graph-instructions {
  color: grey;
  margin: 0.4rem 2rem 0rem 2rem;
  font-size: 0.8rem;
}

.nrel-UniDashboard .nrel-footnote-wrpr {
  background-color: white;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: 1px solid grey;
  margin-top: -1.3rem;
  box-shadow: 5px 5px 3px grey;
  z-index: 2;
}

.nrel-UniDashboard .nrel-legend-footnote {
  position: absolute;
  left: 40%;
  height: 1rem;
  color: grey;
  bottom: 1rem;
  padding-bottom: 0.25rem;
}

.nrel-UniDashboard .home-header {
  margin-bottom: 1rem;
}

.nrel-UniDashboard .nrel-homeTab-description {
  margin-left: 1rem;
  font-size: .7rem;
}

.nrel-UniDashboard .home-wrapper {
  background-color: rgb(247, 247, 248);
  border: 1px solid rgb(219, 218, 218);
  margin: 1rem;
  border-radius: 0.25rem;
  margin-top: 1.25rem;
  padding: 10px;
  padding-bottom: 20px;
}

.nrel-sub-header {
  padding-top: 0.25rem;
  margin-top: 0rem;
  line-height: 1rem;
  font-size: 1rem;
  color: grey;
  margin-left: 0.5rem;
  margin-bottom: 0.8rem;
}

.nrel-tool-list-describer {
  font-size: 0.9rem;
  color: grey;
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.nrel-tool-list {
  color: grey;
  margin-top: 0.25rem;
  line-height: 15px;
  margin-left: 0.25rem;
  font-size: 0.7rem;
}

.about-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
}
.about-section:nth-child(odd) {
  background-color: rgb(223, 230, 237);
  padding: 5px;
}

.home-wrapper span {
  font-weight: bold;
}

.nrel-UniDashboard .home-wrapper ul {
  margin-left: 0.5rem;
}

.about-section#note {
  background-color: white;
  margin: 0rem;
}

.about-section#note h4 {
  margin-left: 1rem;
  margin-bottom: 0rem;
  font-size: 0.8rem;
}

.about-section#note p {
  font-size: 0.6rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.nrel-UniDashboard .home-header {
  text-align: left;
  margin: 0rem;
  margin-top: 1rem;
  margin-left: 2rem;
  color: grey;
  font-weight: 500;
  font-size: 1.3rem;
  float: left;
  width: 100%;
}

.nrel-UniDashboard .additional-resources {
  background-color: rgb(223, 230, 237);
  margin: 1rem;
  margin-top: 4rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(128, 128, 128, 0.411);
}

.nrel-UniDashboard .additional-resources h4 {
  color: rgb(109, 110, 112);
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.nrel-UniDashboard .additional-resources .section-title {
  color: rgb(109, 110, 112);
  font-size: 0.75rem;
  margin-left: 0.7rem;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.nrel-UniDashboard .additional-resources .section-title#link{
  margin-left: 0rem;
}

.nrel-UniDashboard .additional-resources ul a {
  text-decoration: none;
  font-size: 0.7rem;
}
.nrel-UniDashboard .additional-resources ul a:hover {
  text-decoration: underline;
}
.nrel-UniDashboard .additional-resources ul {
  margin-top: 3px;
}

.nrel-UniDashboard .additional-resources li{
  line-height: 1.1rem;
}

.nrel-UniDashboard .additional-resources li::marker {
  color: grey;
}

.nrel-UniDashboard .project-about{
  color: grey;
  margin: 1.5rem;
  font-size: .7rem;
  padding: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.nrel-UniDashboard .project-about {
  color: grey;
  margin: 2rem;
  font-size: 0.8rem;
  padding: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
