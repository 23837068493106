@import url("https://fonts.googleapis.com/css?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

@font-face {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  src: "https://fonts.googleapis.com/css?family=Arimo&display=swap";
  src: "https://fonts.googleapis.com/css?family=Open+Sans&display=swap";
}

:root {
  --dark-grey: #2f2f30;
  --light-navy: #12365b;
  --bluish: #259cc4;
  --charcoal-grey: #47484a;
  --very-light-blue: #e6eff7;
  --deep-aqua: #0b6787;
  --battleship-grey: #74787d;
  --light-peach: #f7deba;
  --dark-grey-blue: #37495b;
  --light-grey-blue: #b6bcc1;
  --light-sky-blue: #d8e3ed;
  --light-blue-grey: #cdd1d4;
  --dark-turquoise: #035e5b;
  --blue-green: #13908b;
  --dark-grey-two: #262627;
  --pale-grey: #f2f6fa;
  --bluey-grey: #979a9e;
  --yellowish: #f7ec6c;
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
}

.App {
  text-align: center;
  justify-content: center;
}

.install-title-wrapper {
  position: relative;
  background-color: white;
  border: 1px solid grey;
  margin: 8px;
  border-radius: 8px;
  min-height: 4rem;
  /* min-width: 40rem; */
}

.install-title-wrapper .install-title {
  color: rgba(87, 151, 182, 0.9);
  float: left;
}

.nav-wrapper {
  margin-top: 2.2rem;
  padding-bottom: 0.5rem;
  text-align: left;
  width: auto;
  margin-left: 40%;
}

.install-title {
  text-align: left;
  margin-top: 1.8rem;
  margin-left: 0rem;
  margin-bottom: 5px;
  color: white;
}

.install-grp-selector {
  text-align: left;
  margin-left: 25px;
  font-size: 18px;
  margin-top: 0px;
}
.install-grp-selector #select {
  font-size: 16px;
  background-color: white;
  height: 27px;
}
#install-add-group {
  position: absolute;
  top: 5.8rem;
  left: 3.1rem;
  font-size: 0.7rem;
  color: var(--dark-grey-blue);
  border: 1px solid grey;
  background-color: white;
  border-radius: 6px;
  height: 1.2rem;
  width: 8rem;
}

.install-tracker {
  font-size: 62.5%;
  min-width: 55rem;
  /* max-width: 1205px; */
  color: var(--dark-grey-blue);
  min-height: calc(80vh - 30px);
  height: fit-content;
  /* width: calc(100%); */
  margin: 1% 5% 1% 12%;
  margin-top: 3rem;
  margin-left: 7rem;
  border-radius: 8px;
  border: 1px solid grey;
  background-color: rgba(148, 146, 146, 0.103);
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
}

.install-tracker .install-item-wrapper {
  color: var(--dark-grey-blue);
  display: grid;
  grid-template-areas:
    "pie summary"
    "table table";
  grid-template-rows: max(315px) auto;
  grid-template-columns: 40% 60%;
  max-height: 85%;
  width: 100%;
  position: relative;
}

.install-item-wrapper .visual-wrapper {
  margin: 8px;
  padding: 3px;
  grid-area: pie;
  min-height: 159px;
  border-radius: 8px 0 0 0;
  border: 1px solid grey;
  background-color: white;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
}

.install-item-wrapper .summary-wrapper {
  margin: 8px;
  display: grid;
  grid-area: summary;
  grid-template-columns: 50% 50%;
  grid-template-rows: 15% 75% 15%;
  grid-template-areas:
    "title title"
    "summary percent"
    "blurb blurb";
  min-height: 159px;
  /* border: 1px solid grey;
  background-color: white; */
  /* border-radius: 8px;
  -webkit-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75); */
  padding-bottom: 5px;
}

.install-catchup-blurb {
  grid-area: blurb;
  margin: 0rem;
}

.install-catchup-blurb p {
  margin: 0rem;
  margin-top: 0.5rem;
  color: dark grey;
  font-weight: bold;
  margin-left: 4px;
  border: 1.25px solid grey;
  margin-right: 5px;
  padding: 2px;
  background-color: rgba(228, 44, 44, 0.315);
  border-radius: 0.25rem;
}

.install-catchup-blurb #install-bold {
  font-weight: bold;
  font-size: 1rem;
  text-decoration: underline;
}

.install-item-wrapper .table-wrapper {
  display: grid;
  position: relative;
  grid-area: table;
  border: 1px solid grey;
  margin: 8px;
  height: auto;
  max-height: 37rem;
  border-radius: 8px;
  background-color: white;
  -webkit-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
}

/* Table css */

.install-table {
  width: 99%;
  margin: 0 8px 8px;
  border: 1px solid grey;
  border-top: none;
  color: #74787d;
  color: var(--battleship-grey);
  font-size: 0.9rem;
  text-align: center;
}


.install-table th {
  position: sticky;
  top: 0rem;
}

.install-table p,
.install-table a {
  font-size: 0.7rem;
  padding: 2px 2px 2px 2px;
  margin-bottom: 5px;
  border-radius: 0.25rem;
}

.install-table a {
  color: rgb(61, 61, 61);
}

.install-table p {
  color: rgb(197, 194, 194);
}

.install-table #instl-header {
  background-color: rgba(87, 151, 182);
  border-bottom: grey;
  color: white;
  /* font-weight: bold; */
}
.install-table #companyGroupRow {
  color: rgb(97, 95, 95);
  background: rgb(206, 205, 205);
}

.install-table #instl-row {
  background: rgba(253, 253, 253, 0.445);
}
.install-table #instl-row:nth-child(odd) {
  background: rgba(196, 214, 223, 0.445);
}

.install-table #instl-cell {
  padding-left: 10px;
  cursor: pointer;
}

/* Install Summary */

.summary-wrapper {
  font-size: 0.8rem;
}

.install-tracker .summary-wrapper .sum-pct-complete,
.install-tracker .summary-wrapper .install-summary-text {
  background-color: rgba(56, 119, 179, 0.315);
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin: 0.25rem;
  margin-top: 0rem;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 2px;
  -webkit-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
}

.install-tracker .summary-wrapper .sum-pct-complete#behind {
  background-color: rgba(228, 44, 44, 0.315);
}

.install-tracker .summary-wrapper .sum-pct-complete#ahead {
  background-color: rgba(96, 228, 44, 0.315);
}
.install-tracker .summary-wrapper .sum-pct-complete p,
.install-tracker .summary-wrapper .install-summary-text p {
  margin: 0rem;
}
.install-tracker .summary-wrapper h3 {
  text-align: center;
  font-weight: bold;
  margin: 0rem;
  margin-top: 0.5rem;
  font-size: 1rem;
}

.summary-wrapper #sum-title {
  grid-area: title;
  font-size: 17px;
  padding: 0px;
  margin: 5px;
  font-weight: 200;
}

.summary-wrapper #sum-title span {
  font-size: 22px;
  font-weight: 500;
}
.summary-wrapper #sum-descriptor {
  margin: 0px;
  padding-bottom: 0px;
  justify-content: left;
  text-align: left;
  font-size: 1em;
}

/* .summary-wrapper .sum-pct-complete {
  background-color: green;
  margin: 1.5rem;
  margin-top: 0rem;
  border-left: 1px solid grey; */
/* background-color: rgb(245, 243, 243); */
/* width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
} */

/* .summary-wrapper .sum-pct-complete p {
  font-size: 1rem;
  margin-bottom: 0rem;
  margin-top: 0.25rem;
} */

.summary-wrapper #sum-value {
  font-size: 19px;
}
.summary-wrapper #sum-value-pct {
  font-size: 3rem;
  text-align: center;
}
.summary-wrapper #sum-unit {
  font-size: 12px;
}

/* Install Visual */
.visual-wrapper #instl-visual {
  margin-top: 8px;
  margin-bottom: 10px;
  width: 90vh;
  height: 90%;
}

.install-pct-wrpr {
  justify-content: center;
  text-align: center;
  border: none;
  border-bottom: 1px solid grey;
  padding: 10px;
  margin-top: 1%;
  padding-bottom: 20px;
}

/* Install Modal */

.install-modal {
  font-size: 0.8rem;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.install-modal h3 {
  text-align: center;
  margin-top: 0rem;
  font-size: 1.2rem;
}

.install-modal .install-modal-content {
  background-color: #fefefe;
  border-radius: 8px;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 496px;
}

.install-modal .install-modal-content #instl-clse {
  width: 20px;
  cursor: pointer;
  position: relative;
  margin-left: 95%;
  top: 5%;
}

.install-modal .install-modal-content #install-update-submit {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.9rem;
  width: 9rem;
  color: rgb(80, 78, 78);
  border: 1.5px solid grey;
  font-size: 1.2rem;
  background-color: white;
  border-radius: 0.25rem;
}

.install-modal .install-modal-content input,
#install-group-input {
  color: var(--dark-grey);
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 15px;
  border-radius: 0.25rem;
  width: 60%;
  border: 1px solid grey;
}

/* Add Group Modal */

.install-addGroup-form {
  position: relative;
  /* background-color: rgba(128, 128, 128, 0.137); */
  border-radius: 8px;
  padding-bottom: 12px;
  padding-top: 6px;
  /* box-shadow: inset 0 0 4px; */
  border: 1px solid grey;
  text-align: center;
  height: 16rem;
}

.install-addGroup-form #install-group-label {
  font-size: 0.9rem;
  margin-bottom: 3px;
}

/* LANDING PAGE */

.install-ganttWrapper {
  margin: 1rem;
  padding: 1rem;
  border: solid 1px grey;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 22px -13px rgba(0, 0, 0, 0.75);
}

.install-gantt {
  width: 95%;
  margin: 1rem;
  border-radius: 1rem;
}

.install-client-name {
  margin-left: 2.6rem;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.2px;
  margin-bottom: 0rem;
  margin-top: 0rem;
  color: rgb(80, 104, 130);
}
.install-nav-btn button {
  margin: 0.75rem;
  height: 2rem;
  width: 6rem;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0.5rem;
  background-color: white;
  border: 1px solid grey;
  color: grey;
}

.install-nav-btn.active button {
  background-color: rgb(233, 233, 233);
  color: rgb(105, 105, 105);
}

.install-client-group {
  font-size: 0.9rem;
  float: left;
  margin-left: 2.3rem;
  height: 1.5rem;
  width: auto;
  min-width: 10rem;
  color: rgb(43, 42, 42);
  border: 1px solid grey;
  border-radius: 0.25rem;
}

.install-datePicker-start {
  position: absolute;
  left: 3.6rem;
}
.install-datePicker-end {
  position: absolute;
  right: 3.4rem;
}

.addPhase {
  position: absolute;
  top: 19rem;
  left: 11.5rem;
  height: 1.5rem;
  width: 8rem;
}

.missing-heartbeats-header {
  height: 3.2rem;
}

.missing-heartbeats-header h1 {
  float: left;
  margin-left: 42%;
  color: grey;
}

.install-download-btn {
right: 1.5rem;

  margin-top: 20.4rem;
  border: 1px solid white;
  border-radius: 0.25rem;
  padding: 4px;
  width: 11rem;
  max-width: 14rem;
  height: 1.2rem;
  background-color: #13908b;
  position: absolute;
  z-index: 2;
}

.install-download-btn a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 0.7rem;
}
.install-download-btn p {
  margin: 0rem;
  margin-top: 0.25rem;
}

.install-toggle-wrapper {
  text-align: center;
  height: 2.3rem;
  position: sticky;
  top: 0rem;
  background-color: white;
  border-bottom: 1px solid grey;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.install-toggle-wrapper span {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.install-toggle-wrapper .react-toggle {
  margin-top: 0.5rem;
  height: 1rem;
}

.install-toggle-wrapper .react-toggle-track {
  margin-top: 0.38rem;
  height: 0.85rem;
  width: 44px;
}

.install-toggle-wrapper .react-toggle-thumb {
  height: 18px;
  width: 18px;
  margin-top: 0.2rem;
}

.install-loading-wrapper {
  width: 80%;
  margin-left: 25%;
  text-align: center;
}

.swt-table-search {
  background-color: rgba(87, 151, 182);
  text-align: center;
  font-size: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  color: white;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  height: 1.3rem;
  border-bottom: 1px solid white;
}

.swt-table-search input {
  height: 0.9rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-top: 2px;
}


.install-table-wrapper{
  max-height: 32rem;
  overflow: scroll;
}