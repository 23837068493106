/* Color palette */
@import url("https://fonts.googleapis.com/css?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

@font-face {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  src: "https://fonts.googleapis.com/css?family=Arimo&display=swap";
  src: "https://fonts.googleapis.com/css?family=Open+Sans&display=swap";
}

body {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
}

:root {
  --ezev-dark-grey: #2f2f30;
  --ezev-bluish: #259cc4;
  --ezev-info: #dfe6ed;
  --ezev-charcoal-grey: #47484a;
  --ezev-deep-aqua: #0b6787;
  --ezev-battleship-grey: #74787d;
  --ezev-light-grey-blue: #b6bcc1;
  --ezev-light-sky-blue: #d8e3ed;
  --ezev-dark-turquoise: #035e5b;
  --ezev-blue-green: #13908b;
  --ezev-dark-grey-two: #262627;
  --ezev-bluey-grey: #979a9e;
  --ezev-muted-blue: #72afc4;
  --ezev-light-grey: #f5f5f5;
  --white: #ffffff;
  --black: #000000;
  --ionev-red: #e75858;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

.swt-main .ezev-app {
  padding-left: 38px;
  padding-top: 28px;
}

.ezev-ic-sawatch {
  height: 90px;
  float: right;
}

.ezev-app {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  margin-left: 115px;
  max-width: 1500px;
}

.swt-ezev-td-frame {
  height:200%;
  width:200%;
}

.ezev-app-geotab {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  text-align: center;
}

.ezev-content {
  margin-bottom: 30px;
}

.ezev-app-content {
  margin: 20px 0;
  width: 90%;
  text-align: left;
}

.H1-ezEV {
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.5px;
  color: var(--ezev-charcoal-grey);
}
.ezev-h2 {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 0.1px;
  color: var(--ezev-charcoal-grey);
}
.ezev-button-font-2 {
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
}
.H3-ezEV {
  font-size: 17px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: 0.1px;
  color: var(--ezev-charcoal-grey);
}
.ezev-checkbox {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.3px;
  color: var(--ezev-dark-grey);
}

.ezEV-Table-Text {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--ezev-charcoal-grey);
}
.ezEV-table-text-right-aligned {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: right;
  color: var(--ezev-battleship-grey);
}
.ezEV-body {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.1px;
  color: var(--ezev-battleship-grey);
}
.ezEV-Table-Text-2 {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: var(--ezev-battleship-grey);
  padding: 2px;
}

.ezev-UI-Text-light {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: var(--ezev-battleship-grey);
}
.ezEV-Body-2 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0px;
  color: var(--ezev-battleship-grey);
}
/* changed this from duplicate 2 */
.ezEV-Body-3 {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0px;
  color: #4c4d4f;
}

#ezev-header .ezev-ic-ezev {
  width: 73px;
  height: 73px;
  display: inline-block;
  padding: 10px;
  box-sizing: content-box;
}

#ezev-header .ezev-heading-text {
  display: inline-block;
  vertical-align: top;
  padding-top: 12px;
  min-height: 100px;
}

#ezev-header .ezev-heading.H1-ezEV {
  display: inline-block;
}

#ezev-header .ezev-heading.ezev-secondary-header,
#ezev-header .ezev-heading .cname {
  color: var(--ezev-bluey-grey);
  display: block;
}

#ezev-header .ezev-heading-text.table .ezev-heading.ezev-secondary-header {
  display: inline-block;
}

/* Select vcl stylesheet */
.ezev-content .ezev-select-groups .ezev-checkbox,
.ezev-content .ezev-selection-container .ezev-select-vcls .ezev-checkbox {
  display: inline-block;
}
#group-list{
  width: 100%;
}

#group-list .ezev-h2 {
  margin-top: 32px;
}

.ezev-button-wrapper {
  display: inline-block;
  margin-right: 5px;
}

.ezev-button-1 {
  display: inline-block;
  width: 115px;
  height: 35px;
  border-radius: 9px;
  border: solid 0.7px var(--ezev-bluey-grey);
  background-color: #ffffff;
  margin-top: 8px;
}

.ezev-button-1.lhs {
  margin-right: 6px;
}

/* same as .ezEV-table-text-right-aligned but centered */
.ezev-button-1 p {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--ezev-battleship-grey);
  margin-top: 8px;
}

.ezev-information-card
{
  margin-top: 15px;
  border-radius: 9px;
  background-color: #dfe6ed;
}

.ezev-content .ezev-information-card.vcl-select {
  width: 486px;
  margin-bottom: 20px;
}

.ezev-information-card.single {
  height: auto;
  width: 100%;

}

.ezev-content .ezev-information-card.ev 
, .ezev-content .ezev-information-card.single {
  width: 486px;
  height: auto;
}

.ezev-information-card {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: var(--ezev-charcoal-grey);
}

.ezev-information-card .ezev-card-content {
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  min-height: 17.4rem;
}

 .ezev-information-card .ezev-card-content p {
  padding-top: 15px;
}

.ezev-information-card .ezev-divider {
  height: 0;
  border: solid 1px var(--ezev-light-grey-blue);
}

.ezev-selection-container .ezev-button-1 {
  display: inline-block;
}

.ezev-selection-container .ezev-explanation.ezEV-body {
  margin-top: 15px;
  width: 485px;
  height: 66px;
}

.ezev-selection-container .ezev-select-groups,
.ezev-selection-container .ezev-select-vcls,
.ezev-selection-container .ezev-select-metrics {
  margin-top: 25px;
  margin-bottom: 25px;
}

.ezev-selection-container .ezev-select-groups .ezev-group-item,
.ezev-selection-container .ezev-select-vcls .ezev-vcl-item,
.ezev-selection-container .ezev-select-metrics .ezev-metric-item {
  margin-bottom: 17px;
}

.ezev-selection-container .ezev-select-groups .ezev-group-item .ezev-selector,
.ezev-selection-container .ezev-select-vcls .ezev-vcl-item .ezev-selector,
.ezev-selection-container
  .ezev-select-metrics
  .ezev-metric-item
  .ezev-selector {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.51);
  border: solid 1px var(--ezev-battleship-grey);
  background-color: #ffffff;
  margin-right: 12px;
  display: inline-block;
}

.ezev-selection-container
  .ezev-select-groups
  .ezev-group-item
  .ezev-selector:hover,
.ezev-selection-container .ezev-select-vcls .ezev-vcl-item .ezev-selector:hover,
.ezev-selection-container
  .ezev-select-metrics
  .ezev-metric-item
  .ezev-selector:hover {
  cursor: pointer;
}

.ezev-selection-container
  .ezev-select-groups
  .ezev-group-item
  .ezev-selector.checked,
.ezev-selection-container
  .ezev-select-vcls
  .ezev-vcl-item
  .ezev-selector.checked,
.ezev-selection-container
  .ezev-select-metrics
  .ezev-metric-item
  .ezev-selector.checked {
  background-color: var(--ezev-bluish);
  border-color: var(--ezev-bluish);
  box-shadow: inset 0 0 0;
}

.ezev-selection-container
  .ezev-select-groups
  .ezev-group-item
  .ezev-selector
  .ezev-check-mark,
.ezev-selection-container
  .ezev-select-vcls
  .ezev-vcl-item
  .ezev-selector
  .ezev-check-mark,
.ezev-selection-container
  .ezev-select-metrics
  .ezev-metric-item
  .ezev-selector
  .ezev-check-mark {
  color: #ffffff;
  margin-left: 4px;
  font-size: 16px;
  user-select: none;
}

.ezev-selection-container .ezev-select-groups .ezev-group-item .ezev-checkbox,
.ezev-selection-container .ezev-select-vcls .ezev-vcl-item .ezev-checkbox,
.ezev-selection-container
  .ezev-select-metrics
  .ezev-metric-item
  .ezev-checkbox {
  display: inline-block;
  height: 25px;
  opacity: 0.8;
  vertical-align: top;
}

.ezev-selection-container .ezev-select-groups,
.ezev-selection-container .ezev-select-vcls {
  display: block;
}

.ezev-button-2 {
  width: 165px;
  height: 50px;
  background-color: var(--ezev-bluish);
  margin-top: 25px;
  box-sizing: content-box;
  transition: all ease-in .2s;
}

.ezev-button-2 .ezev-button-font-2 {
  padding-top: 13px;
  padding-left: 6.5px;
  width: 152px;
  height: 24px;
  box-sizing: content-box;
}

.ezev-button-3 {
  display: inline-block;
  margin-right: 15px;
  width: 165px;
  height: 50px;
  background-color: var(--ezev-blue-green);
  transition: all ease-in .2s;
}

.ezev-button-3.disabled{
  background-color: var(--ezev-bluey-grey) !important;
  cursor: default !important;
  pointer-events: none;
}

.ezev-button-3:hover {
  background-color: var(--ezev-dark-turquoise);
  cursor: pointer;
  transition: all ease-in .2s;
}

.ezev-button-3 .ezev-button-font-2 {
  padding-top: 13px;
  height: 24px;
}

.ezev-group-selector,
.ezev-table-selector {
  height: 50px;
  background-color: #ffffff;
}

.ezev-group-selector {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 35px;
}

.ezev-table-selector {
  width: 340px;
  text-indent: 40px;
  margin-right: 15px;
  margin-bottom: 5px;
}

#group-list  h2 {
  margin-bottom: 22px;
}

/* Candidates */
.ezev-content .ezev-candidate-selection {
  margin-top: 50px;
}

.ezev-content .ezev-candidate-selection .ezev-candidate-options-wrapper {
  text-align: center;
}

.ezev-candidate-selection .ezev-candidate-header {
  width: 572px;
  height: 54px;
  opacity: 0.9;
  padding-bottom: 5px;
}

#ezev-candidate-options {
  width: 1000px;
  display: inline-block;
}

.ezev-button-1.deselect p {
  color: #ffffff;
}

.ezev-button-1.deselect:hover p {
  color: #ffffff;
}
/* Candidates page */
.ezev-candidate-explanation-wrapper {
  text-align: center;
  margin-top: 31px;
}

.ezev-candidate-explanation {
  display: inline-block;
  text-align: left;
  width: 100%;
  height: 121px;
}

.ezev-candidate-explanation .ezev-candidate-explanation-text {
  width: 87%;
}

.ezev-candidate-explanation a {
  color: var(--ezev-bluish);
  text-decoration: none;
  cursor: pointer;
}

.ezev-button-1:hover {
  border: solid 0.7px var(--ezev-dark-grey-two);
  cursor: pointer;
}

.ezev-button-1:hover p {
  color: var(--ezev-dark-grey-two);
  cursor: pointer;
}

.ezev-button-2:hover {
  background-color: var(--ezev-deep-aqua);
  cursor: pointer;
  transition: all ease-in .2s;
}

.ezev-candidate-additional {
  margin-bottom: 10px;
}

/* Single vcl landing page */
/* Split columns code */
.ezev-content.split{
  display:flex;
}

.ezev-subcontent.split {
  display: flex;
  justify-content: space-between;
}

.ezev-subcontent.split.single-vcl-pg {
  grid-gap: 20px;
}

.ezev-content.split .ezev-lhs,
.ezev-subcontent.split .ezev-lhs {
  grid-area: "lhs";
}

.ezev-lhs{
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  width: 429px;
}

.ezev-rhs{
  margin-left: 1rem;
  max-width: 50%;
}


.ezev-content.split .ezev-rhs,
.ezev-subcontent.split .ezev-rhs {
  grid-area: "rhs";
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 10%;
}

.ezev-content .ezev-replacement,
.ezev-content .ezev-compare {
  margin-top: 20px;
  z-index: 1;
}

.ezev-replacement .recco,
.ezev-evse-parking-table .table,
.ezev-evse-charging-table .table,
.ezev-midday-charge .charge-string,
 .ezev-energy-explanation .ezev-energy-explanation-string,
 .ezev-upfits-list .ezev-upfits-string {
  height: 27px;
  opacity: 0.9;
  color: var(--ezev-bluey-grey);
}

 .ezev-energy-explanation {
  cursor: pointer;
  padding-bottom: 35px;
}

 .ezev-energy-explanation .ezev-energy-explanation-string {
  display: inline-block;
}

.ezev-upfits-list {
  padding-top: 10px;
  display: inline-block;
}

 .ezev-energy-explanation .ezev-energy-explanation-bubble {
  display: none;
}

.ezev-energy-explanation:hover .ezev-energy-explanation-bubble {
  display: block;
}

.ezev-info-icon-phev {
  height: 11px;
  width: 11px;
  padding-left: 2px;
  display: inline-block;
  padding-bottom: 9px;
  box-sizing: content-box;
}

.ezev-content .ezev-replacement .recco.ev,
.ezev-content .ezev-replacement .recco.evse {
  padding-top: 4px;
}

.ezev-content .ezev-replacement img,
.ezev-content .ezev-visuals-wrapper img {
  height: 160px;
  margin-top: 27px;
}

.ezev-content .ezev-selection-container {
  width: 403px;
}

.ezev-content .ezev-selection-container .heading,
.ezev-content .ezev-midday-charge,
.ezev-content .ezev-energy-explanation {
  height: 56px;
  opacity: 0.9;
  margin-top: 31px;
  width: 486px;
  
}

.ezev-content .ezev-energy-explanation {
  margin-top: 15px;
}

.ezev-content .ezev-selection-container .ezev-metric-blurb {
  display: block;
  margin-left: 33px;
}

.ezev-scored-on-explanation {
  padding-bottom: 20px;
  max-width: 600px;
  margin-right: 20px;
  font-size: 14px;
}

/* Single EV */
.ezev-content .ezev-compare select {
  margin-top: 12px;
  margin-bottom: 5px;
}

.ezev-savings-header {
  text-align: left;
}


.ezev-subcontent.phev,
.ezev-subcontent.bev {
  text-align: left;
  margin-top: 12rem;
}

 .ezev-savings-table,
 .ezev-evse-table {
  margin-top: 10px;
  border-collapse: collapse;
  text-align: left;
}

 .ezev-savings-table thead th,
 .ezev-evse-table thead th {
  width: 146px;
  height: 59px;
  border: solid 1px #979797;
  background-color: var(--ezev-light-sky-blue);
  text-align: center;
  font-size: 16px;
}

.ezev-savings-table tbody td,
.ezev-evse-table tbody td {
  width: 146px;
  height: 59px;
  border: solid 1px #979797;
  background-color: #ffffff;
}

.ezev-subcontent .ezev-explanation-container .explanation {
  margin-top: 10px;
}

.ezev-savings-table .ezev-change-arrow,
.ezev-table-wrapper .ezev-change-arrow {
  width: 15px;
  padding-right: 3px;
}

.ezev-savings-table .ezev-change-arrow.asc,
.ezev-table-wrapper .ezev-change-arrow.asc {
  padding-bottom: 3px;
}

.ezev-table-wrapper .ezev-no-change-arrow {
  padding-left: 15px;
}

/* Landing page */
.ezev-button-1.landing {
  width: 165px;
}

.ezev-download-container .heading {
  margin-bottom: 5px;
}

.ezev-download-container .ezev-button-3 {
  margin-top: 15px;
}

/* Single EVSE */


.ezev-content .ezev-subcontent .ezev-evse-parking-table .table {
  display: inline-block;
}

.ezev-evse-parking-table{
  width: 100%;
  margin: 0 1rem 1rem;
  max-width: 40rem;
}

.ezev-evse-charging-table{
  width: 100%;
  margin: 0 1rem;
}

.ezev-evse-parking-table table{
  width: 100%;
}

.ezev-savings-table-container{
  width: 894px;
  margin: 0 1rem;
}
.ezev-inputs-table-container{
  max-width: 900px;
}

.ezev-evse-charging-table table{
  width: 100%;
}

.ezev-evse-table.padded-table {
  margin-bottom: 10px;
}

.ezev-evse-table.parking-table {
  margin-bottom: 5px;
}

.ezev-table-expand-link {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  text-align: right;
  color: var(--ezev-bluish);
  
}

.ezev-table-expand-link:hover {
  text-decoration: underline;
}

.ezev-content .ezev-subcontent .ezev-evse-explanation {
  margin-top: 5px;
}

.ezev-content .key {
  text-align: left;
}

.ezEV-Table-Text {
  text-align: left;
  padding: 2px;
}

#ezev-header .ezev-info-container {
  display: inline-block;
}

#ezev-header .ezev-info-icon {
  height: 12px;
  width: 12px;
  padding-bottom: 16px;
  padding-left: 2px;
  display: inline-block;
}

#ezev-header .ezev-info-text {
  padding: 8px;
  margin-left: 10px;
  border: 1px solid var(--ezev-bluey-grey);
  box-shadow: 2px 2px 4px var(--ezev-charcoal-grey);
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 13px;
  display: inline-block;
  color: var(--ezev-charcoal-grey);
  position: absolute;
}




.ezev-subheading {
  opacity: 0.9;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.1px;
  color: var(--ezev-charcoal-grey);
}

.ezev-subheading.table {
  /* width: 395px; */
  height: 22px;
}

.ezev-content .ezev-visuals-wrapper {
  margin-top: 6px;
  max-width: 600px;
  margin-right: 20px;
  height: 8rem;
}

.ezev-table-footnotes {
  padding-top: 20px;
}

.ezev-table-footnotes.ezEV-body {
  font-size: 14px;
}

/* No results */
.ezev-table-wrapper .ezev-subheading.no-results {
  padding-top: 10px;
  text-align: center;
}

/* Scores container */
.ezev-content .ezev-score-visuals{
  margin-left: -1rem;
  position: relative;
  min-width: 400px;
}

.ezev-content .ezev-score-visuals .ezev-svg-overall {
  width: 73px;
  padding-right: 7px;
  box-sizing: content-box;
  display: inline-block;
}

.ezev-content .ezev-score-visuals .ezev-overall-img {
  width: 88px;
}

.ezev-content .ezev-score-visuals .ezev-svg-score {
  width: 58px;
  height: 85px;
  margin-top: 20px;
  margin-left: 18px;
  display: inline-block;
}

.ezev-content .ezev-score-visuals .ezev-score-img {
  width: 58px;
  height: 85px;
}

.ezev-svp-no-data-text {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #47484a;
  padding-top: 10px;
}

.ezev-content .ezev-score-explanation {
  padding: 8px;
  margin-left: 30px;
  border: 1px solid var(--ezev-bluey-grey);
  box-shadow: 2px 2px 4px var(--ezev-charcoal-grey);
  border-radius: 5px;
  width: 300px;
  position: absolute;
  z-index: 2;
  background-color: white;
  font-size: 13px;
  bottom: 100px;
}

.ezev-content .ezev-score-explanation.overall {
  margin-left: 90px;
}

.ezev-content .ezev-score-explanation.economics {
  margin-left: 160px;
}

.ezev-content .ezev-score-explanation.energy,
.ezev-content .ezev-score-explanation.energy-null {
  margin-left: 220px;
}

.ezev-content .ezev-score-explanation.parking {
  margin-left: 290px;
}

.ezev-content .ezev-score-explanation.confidence {
  margin-left: 350px;
}

/* Links */
.ezev-link-wrapper {
  display: inline-block;
  margin-right: 5px; /* This only needs to be on first button but added to all */
  box-sizing: content-box;
}

.ezev-link {
  display: inline-block;
  min-width: 115px;
  height: 27px;
  padding: 8px 3px 0 3px;
  border-radius: 9px;
  border: solid 0.7px var(--ezev-bluey-grey);
  background-color: #ffffff;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: var(--ezev-battleship-grey);
  text-decoration: none;
  box-sizing: content-box;
}

.ezev-link-wrapper.ezev-inputs-button .ezev-link {
  min-width: 140px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.ezev-link:hover {
  border: solid 0.7px var(--ezev-dark-grey-two);
  color: var(--ezev-dark-grey-two);
  cursor: pointer;
}

.ezev-link-wrapper.table {
  margin-bottom: 20px;
}

/* Edit candidate page */
.ezev-edit-candidate-wrapper {
  width: 500px;
  padding: 5px;
  margin-left: 100px;
  margin-top: 15px;
  border-radius: 5px;
  border-color: black;
  border: solid;
  border-width: 1px;
}
.ezev-candidatelist-item {
  font-size: 12pt;
  height: 35px;
  width: 355px;
  list-style-type: none;
}

.ezev-candidate-label {
  display: inline;
  font-size: 12pt;
  text-align: right;
  height: 25px;
}

.ezev-candidate-input {
  display: inline;
  position: absolute;
  text-align: right;
  font-size: 12pt;
  margin-left: 25px;
  height: 20px;
  left: 400px;
  border-radius: 5px;
}

.ezev-button-4 {
  width: 100px;
  height: 35px;
  font-size: 15px;
  margin-top: 25px;
  margin-left: 100px;
  border-radius: 10px;
}

.ezev-wrapper {
  top: 15px;
  left: 125px;
  /* position:absolute; */
}

.ezev-processing-page {
  margin-top: 32px;
}

.ezev-setting-costs,
.ezev-setting-threshholds {
  background-color: #dfe6ed;
  margin: 10px;
  padding: 20px;
  padding: 20px;
  border-radius: 3px;
}

.ezev-settinglist-item {
  font-size: 12pt;
  height: 35px;
  width: 355px;
  list-style-type: none;
}

.ezev-setting-label {
  display: inline;
  position: absolute;
  font-size: 12pt;
  text-align: right;
  right: 105px;
  height: 25px;
}

.ezev-setting-input {
  display: inline;
  position: absolute;
  text-align: right;
  margin-right: 20px;
  font-size: 12pt;
  right: 0px;
  height: 20px;
  width: 75px;
  border-radius: 5px;
}

/* Update settings page */
.ezev-settinglist {
  padding-left: 0;
}

.ezev-button-1 .ezev-settinglist-update {
  margin-top: 0;
}

.ezev-settings-form-error-list li {
  color: var(--ionev-red);
}

.ezev-settings-form {
  position: absolute;
}

/* Inputs page */
.ezev-inputs-settings{
  margin-bottom: 10px;
}
.ezev-inputs-content .ezev-inputs-settings,
.ezev-inputs-content .ezev-inputs-vehicles {
  padding-top: 30px;
}

/* Vehicles table (inputs page) */
.ezev-inputs-content .ezev-tables-explanation-wrapper {
  display: grid;
  height: 50px;
  grid-template-columns: 1fr 200px;
  padding-top: 10px;
}

.ezev-inputs-content .ezev-table-download-link-wrapper {
  text-align: right;
  padding-top: 0;
}

.ezev-inputs-content .ezEV-Table-Text-2 {
  text-align: left;
}

.ezev-candidate-update-table {
  background-color: rgba(128, 128, 128, 0.178);
  width: 100%;
  margin-top: 30px;
  border: 1px solid #ccc;
  border-collapse: collapse;
  overflow: scroll;
}

.ezev-candidate-update-table tr:nth-child(even) {
  background-color: white;
  padding: 14px;
  border: none;
}
.ezev-candidate-update-table tr:nth-child(odd) {
  background-color: var(--ezev-light-grey);
  border: none;
}
.ezev-candidate-update-table th {
  background-color: rgba(41, 40, 40, 0.274);
  padding: 10px;
  border: none;
  background-color: var(--ezev-muted-blue);
  font-weight: 700;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
}

.ezev-candidate-update-table td {
  justify-content: center;
  background-color: none;
  padding: 5px;
  border: none;
}

.ezev-candidate-update-table th:nth-child(3),
.ezev-candidate-update-table td:nth-child(3),
.ezev-candidate-update-table th:nth-child(4),
.ezev-candidate-update-table
  td:nth-child(4)
  .ezev-candidate-update-table
  th:nth-child(6),
.ezev-candidate-update-table
  td:nth-child(6)
  .ezev-candidate-update-table
  th:nth-child(7),
.ezev-candidate-update-table td:nth-child(7) {
  width: 10%;
}
.ezev-candidate-update-table td:nth-child(6) {
  padding-left: 30px;
}

.ezev-candidate-update-table th:nth-child(2),
.ezev-candidate-update-table td:nth-child(2) {
  width: 20%;
}

.ezev-candidate-update-table .ezev-candidate-table-input {
  font-size: 0.85rem;
  background-color: rgba(255, 255, 255, 0);
  background-color: none;
  border: none;
  width: 100%;
}

.candidate-update-table-desc {
  color: var(--ezev-battleship-grey);
}
.ezev-candidate-button-container {
  margin-left: 25%;
  padding-top: 0px;
}

.ezev-candidate-button-container .ezev-button-4 {
  margin-top: 0px;
}

.ezev-loading-wrapper {
  width: 12rem;
  height: 12rem;
  margin: auto;
}

.ezev-infocard-loading-wrapper {
  width: 12rem;
  height: 12rem;
  margin: 3rem auto auto auto;
}


.ezev-score-chart#Overall {
  position: relative;
  width: 7.2rem;
  height: 7.2rem;
  top: .7rem;
}

.ezev-score-chart#Confidence,
.ezev-score-chart#Energy,
.ezev-score-chart#Economics,
.ezev-score-chart#Parking {
  position: relative;
  width: 6rem;
  height: 6rem;
}

.ezev-score-circle-value {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 1.3rem;
  font-weight: bold;
  font-size: 19px;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--ezev-charcoal-grey);
  cursor:default;
}

.ezev-score-chart#Overall .ezev-score-circle-value {
  top: 1.6rem;
  font-size: 24px;
}

.ezev-score-circles {
  display: inline-block;
  width: 100%;
}

.ezev-selected-checkbox {
  accent-color: #259cc4;
  height: 18px;
  width: 18px;
  background-color: white;
  cursor: pointer;
}

.ezev-pdf-download-button-container {
  display: inline-block;
  position: relative;
}

.ezev-pdf-download-tooltip-wrapper {
  padding: 8px;
  border: 1px solid #979a9e;
  box-shadow: 2px 2px 4px #47484a;
  border-radius: 5px;
  width: 300px;
  z-index: 1;
  background-color: white;
  font-size: 14px;
  display: inline-block;
  color: #47484a;
  position: absolute;
  bottom: 65px;
}


.ezev-error-header {
  font-size: 20px;
  font-weight: 'bold';
  color: var(--ezev-charcoal-grey);
  padding-bottom: 5px;
  margin-top: 20px;
}

.ezev-error-wrapper {
  padding-top: 10px;
}

.ezev-error-text {
  font-size: 16px;
  padding-bottom: 5px;
  max-width: 55rem;
  color: var(--ezev-battleship-grey);
}

.ezev-pdf-download-tooltip-text {
  margin: 0;
}

@media screen and (max-width: 1100px) {
  .ezev-evse-parking-table {
    width: 90%;
    min-width: 400px;
  }
  .ezev-evse-charging-table {
    width: 90%;
    min-width: 400px;
  }
}

@media (max-width: 1605px) {
  .ev-inputs-subheader {
    padding-bottom: 1.2rem;
  }
}

@media (max-width: 1200px) {
  .ev-inputs-subheader {
    padding: 0rem;
  }
}

/* Disable click events on score circles */
.ezev-score-circle .recharts-wrapper * { outline: none; }