#ezev-header .ezev-heading {
  display: inline-block;
}

/* Extra customer css for v 6 */
/* Arrows */
/* .ReactTable .rt-table .rt-tr .rt-th.-sort-asc div {
  background-image: url("https://images.sawatchlabs.com/ic-arrow-asc-20px-16px.png");
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: right 5px center;
} */

.ReactTable .rt-table .rt-tr .rt-th.-sort-desc div {
  background-image: url("https://images.sawatchlabs.com/ic-arrow-desc-20px-16px.png");
  background-repeat: no-repeat;
  background-size: 7px;
  background-position: right 5px center;
}

.ReactTable .rt-table .rt-tr .rt-th div {
  background-image: url("https://images.sawatchlabs.com/ic-arrow-unsort-20px-36px.png");
  background-repeat: no-repeat;
  background-size: 7px;
  font-size: 16px;
  background-position: right 5px center;
}

.ezev-tables-wrapper {
  margin-top: 30px;
}

.ezev-table-wrapper.highlight-six
  .ReactTable
  .rt-table
  .rt-tbody
  .rt-tr.-odd
  .rt-td:nth-child(6) {
  background-color: #d4e3e3;
}

.ezev-table-wrapper.highlight-six
  .ReactTable
  .rt-table
  .rt-tbody
  .rt-tr.-even
  .rt-td:nth-child(6) {
  background-color: #ddecec;
}

.ezev-table-wrapper.highlight-seven
  .ReactTable
  .rt-table
  .rt-tbody
  .rt-tr.-odd
  .rt-td:nth-child(7) {
  background-color: #d4e3e3;
}

.ezev-table-wrapper.highlight-seven
  .ReactTable
  .rt-table
  .rt-tbody
  .rt-tr.-even
  .rt-td:nth-child(7) {
  background-color: #ddecec;
}

.rt-thead.-header,
.ReactTable.ezev-vehicles-table .rt-table .rt-thead.-headerGroups {
  background-color: var(--ezev-muted-blue);
  font-weight: 700;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
}

.ReactTable.ezev-vehicles-table {
  color: var(--ezev-dark-grey);
}

.ReactTable.ezev-vehicles-table .rt-table .rt-thead.-headerGroups,
.ReactTable.ezev-vehicles-table .rt-thead.-header {
  /* background: var(--ezev-muted-blue); */
  background: var(--ezev-light-sky-blue);
  height: 55px;
  padding-bottom: 10px;
  text-align: center;
}

.ReactTable.ezev-vehicles-table .rt-thead.-headerGroups .rt-tr {
  text-align: center;
}

.ReactTable.ezev-vehicles-table .rt-thead.-headerGroups {
  border-bottom: none;
}

.rt-tbody .rt-tr {
  min-height: 50px;
  text-align: left;
}

.rt-tbody .rt-tr.-odd {
  background-color: var(--ezev-light-grey);
}

.rt-tbody .rt-tr.-even {
  background-color: var(--white);
}

.rt-tbody .rt-tr .rt-td a {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
  font-size: 16px;
}

.rt-tbody .rt-tr:hover .rt-td a,
.ReactTable .candidates-row:hover .rt-td,
.ezev-vehicles-table-cell .rt-tbody .rt-tr:hover .rt-td {
  text-decoration: underline;
  color: var(--ezev-bluish);
}

.rt-tbody .rt-tr:hover .rt-td .ezev-vehicles-table-cell {
  cursor: default;
}

.ReactTable .rt-td .asset_id a {
  color: var(--ezev-bluish);
  text-decoration: underline;
}

.ReactTable .rt-th.-sort-desc,
.ReactTable .rt-th.-sort-asc {
  text-decoration: underline;
}

.ReactTable.ezev-vehicles-table {
  /* margin: 15px auto 0px */
  margin-top: 0px;
}

/* REACT TABLE VERSION 6 */
/* From stylesheet */

.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.ReactTable * {
  box-sizing: border-box;
}

.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* .ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 5px 7px;
	font-size: inherit;
	border-radius: 3px;
	font-weight: normal;
	outline-width: 0
} */

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
  min-height: 10vh;
}

/* .ReactTable .rt-thead .rt-tr .rt-td .ezev-table-cell-num a {
	text-align: right;
} */

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  margin: auto;
  margin-top: 5px;
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  font-size: 1.5rem;

}

.rt-th .ezev-vehicles-table-header{
  margin: 0rem;
  height: 2rem;
}
.rt-th .ezev-vehicles-table-header{
  margin: 0rem;
  height: 2rem;
}



.ezev-bev-table-wrapper{
  width: 100%;
}

.ezev-phev-table-wrapper{
  width: 100%;
}
.ezev-ice-table-wrapper{
  width: 100%;
}

.ezev-bev-table-wrapper .rt-thead.-headerGroups,
.ezev-phev-table-wrapper .rt-thead.-headerGroups
 {
  text-align: left;
  height: 7vh;
}


.ezev-bev-table-wrapper .rt-thead.-headerGroups,
.ezev-phev-table-wrapper .rt-thead.-headerGroups
 {
  text-align: left;
  height: 7vh;
}


/* .ReactTable.ezev-vehicles-table .rt-thead .rt-th,
.ReactTable.ezev-vehicles-table .rt-thead .rt-td {
  margin: 5px auto 0;
} */

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer;
}

/* .ReactTable .rt-thead .rt-th:focus {
	outline-width: 0
} */

/* .ReactTable .rt-thead .rt-resizable-header {
	overflow: visible;
} */

/* .ReactTable .rt-thead .rt-resizable-header:last-child {
	overflow: hidden
} */
/* 
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
	left: 100%;
	top: 50%;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none
} */

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  /* border-left-color: #fff;
	border-width: 8px;
	margin-top: -8px */
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  /* border-left-color: #f7f7f7;
	border-width: 10px;
	margin-top: -10px */
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 6vh;
}

.ReactTable .candidates-row {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  min-height: 6vh;
  cursor: pointer;
}

.ReactTable .candidates-row .rt-td {
  cursor: pointer;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #72afc4;
}


.ReactTable .rt-th,
.ReactTable .rt-td {
  display: inline-block;
  display: table-cell;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: normal;
  padding: 11px 5px 7px 5px;
  transition: 0.3s ease;
  font-size: 16px;
  overflow: hidden;
  transition-property: width, min-width, padding, opacity;
}

/* .ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
	width: 0 !important;
	min-width: 0 !important;
	padding: 0 !important;
	border: 0 !important;
	opacity: 0 !important
} */

.ReactTable .rt-noData {
  display: none;
}

.ReactTable .-loading {
  display: none;
}

.ReactTable .-loading > div {
  display: none;
}

/* End from stylesheet */

.ReactTable {
  margin: 15px auto 0px;
  max-height: 60vh;
  border-spacing: 0;
  border-collapse: collapse;
  color: var(--black);
}


.ezev-button-1.table {
  display: block;
  margin-bottom: 20px;
}

.ezev-table-extra{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.ezev-table-wrapper .ezev-table-extra .ezev-table-extra-text {
  display: inline-block;
  /* padding-left: 15px; */
  vertical-align: middle;
}

/* Table download button */
.ezev-table-download-link {
  width: 165px;
  height: 50px;
  background-color: var(--ezev-blue-green);
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #ffffff;
  transition: all ease-in .2s;
  margin-top: 15px;
}

.ezev-table-download-link:hover {
  background-color: var(--ezev-dark-turquoise);
  cursor: pointer;
  transition: all ease-in .2s;
}

.ezev-table-download-link p {
  padding-top: 13px;
}

.ezev-table-download-link-text-super-admin {
  font-size: 18px;
}

.ezev-react-table .pagination-bottom .-pagination {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.1px;
  color: var(--ezev-battleship-grey);
  padding-top: 20px;
 text-align: center;
}

.ezev-react-table .pagination-bottom .-pagination .-previous {
  display: inline-block;
  padding-right: 5px;
}

.ezev-react-table .pagination-bottom .-pagination .-center {
  display: inline-block;
}

.ezev-react-table .pagination-bottom .-pagination .-center .-pageInfo {
  display: inline-block;
}

.ezev-react-table .pagination-bottom .-pagination .-center .-pageJump {
  display: inline-block;
  font-size: 12px;
}

.ezev-react-table .pagination-bottom .-pagination .-center .-pageJump input {
  display: inline-block;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.1px;
  color: var(--ezev-battleship-grey);
  width: 3rem;
}

.-btn {
  width: 65px;
  height: 20px;
  padding: 0 5px;
  border: 0.7px solid #979a9e;
  border-radius: 9px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: .3px;
  text-align: center;
  color: #74787d;
  text-decoration: none;
  cursor: pointer;
  transition: all ease-in-out .2s;
}

.-btn:disabled {
  cursor:default;
  color: rgba(16, 16, 16, 0.3);
  border-color: rgba(16, 16, 16, 0.2);
}

.-btn:hover:not([disabled]) {
    border-color: #262627;
    color: #262627;
    transform: scale(1.02);
    transition: all ease-in-out .2s;
}

.-pageInfo {
  padding: 0 5px;
  font-size: 12px;
  color: #74787d;
  letter-spacing: .1px;
}

.ezev-react-table .pagination-bottom .-pagination .-center .-totalPages {
  display: inline-block;
}

.ezev-react-table .pagination-bottom .-pagination .-next {
  display: inline-block;
  padding-left: 5px;
}

@media (max-width: 1200px) {
  #ezev-header .ezev-heading {
    font-size: 32px;
  }
  #ezev-header .ezev-subheading {
    font-size: 16px;
  }
  /* #ezev-header .ezev-subtext {
    font-size: 12px;
  } */
}

@media (max-width: 1000px) {
  #ezev-header .ezev-info-text {
    width: 200px;
  }
}

@media (max-width: 870px) {
  #ezev-header .ezev-info-text {
    width: 150px;
    font-size: 10px;
  }
}
