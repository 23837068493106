body {
    margin: 0;
    font-family: 'Inter', sans-serif;
}

/* This styling is for the Vehicle Classes multi select componenet. Specifically the dropdown menu */
.panel-content, .dropdown-content {
    max-height: 250px;
}
.options {
    overflow-y: scroll;
    max-height: 215px!important;
    padding-bottom: 5px;
}

.select-panel {
    overflow: hidden;
}