@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff2)
      format("woff2"),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.woff)
      format("woff"),
    url(https://fonts.sawatchlabs.com/Open_Sans/OpenSans-Regular.ttf)
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

.nrel-SteDashboard html {
  font-size: 62.5%;
}

.nrel-bold {
  font-weight: bold;
  font-size: 1.2rem;
}
.nrel-bold-small {
  font-weight: bold;
  font-size: 1rem;
  color: rgb(7, 7, 7);
}
.nrel-SteDashboard {
  font-size: 14px;
  font-size: 1.4rem;
  font-size: 62.5%;
  height: 100%;
}
.nrel-SteDashboard h1 {
  font-size: 24px;
  font-size: 2.4rem;
}

.nrel-SteDashboard .nrel-dash-landing .nrel-dash-title {
  font-style: normal;
  font-stretch: normal;
  color: #47484a;
  margin-top: 3.6rem;
  margin-left: 0rem;
  margin-bottom: 0rem;
  font-weight: 500;
  line-height: 1.17;
  letter-spacing: 0.5px;
  font-size: 1.5rem;
}

.nrel-SteDashboard .nrel-dash-title2 {
  color: grey;
  margin-left: 0rem;
  margin-top: 0.6rem;
  display: inline-block;
  font-style: italic;
  font-weight: 300;
  font-size: 1rem;
  width: 12rem;
}

.nrel-SteDashboard .nrel-pwrd-by-image {
  position: relative;
  margin-left: 0rem;
  width: 2rem;
  left: 5.5rem;
}

.nrel-SteDashboard .nrel-nrel-logo {
  width: 7rem;
  position: absolute;
  right: 10rem;
  top: 5.4rem;
}

.nrel-SteDashboard .nrel-sawatch-logo {
  width: 7rem;
  position: absolute;
  right: 2rem;
  top: 4rem;
}

.nrel-SteDashboard .nrel-dash-user-info-wrpr {
  grid-area: info;
  background-color: rgb(223, 230, 237);
  width: 21rem;
  height: 5.5rem;
  margin-top: 1.2rem;
  margin-left: 0rem;
  border-radius: 1rem;
  padding: 1rem;
}

.nrel-SteDashboard .nrel-fleet,
.nrel-SteDashboard .nrel-telematics,
.nrel-SteDashboard .nrel-observation,
.nrel-SteDashboard .nrel-total-vehicles,
.nrel-SteDashboard .nrel-analyzed {
  margin: 0rem;
  margin-top: 0.1rem;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #47484a;
}
.nrel-fleet {
  margin-top: 0rem;
}

.nrel-SteDashboard .nrel-info-value {
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  color: #74787d;
}
.nrel-SteDashboard .nrel-unit {
  font-size: 0.4rem;
  color: grey;
}
.nrel-SteDashboard .nrel-overallStats-wrpr {
  margin-left: 15%;
  display: grid;
  grid-area: stats;
  width: 25rem;
  height: auto;
  padding: 0.25rem;
  border: 1px solid rgb(182, 182, 182);
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  margin-top: 1.2rem;
  box-shadow: 5px 5px 3px grey;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-stats-wrapper {
  display: grid;
  grid-template-columns: 30% 27% 25% 18%;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-indicator-arrow {
  width: 0.6rem;
}
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-overallStats {
  margin: 0rem;
  color: rgb(82, 80, 80);
  font-size: 18px;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.1px;
  margin-left: 1.6rem;
}
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-tco,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-ghg,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-fuel,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-vcls {
  margin: auto;
  display: inline-block;
  /* margin-left: 6%; */
  text-align: center;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 1rem;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-tco .nrel-value#inc,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-ghg .nrel-value#inc,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-fuel .nrel-value#inc {
  color: red;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-tco .nrel-value#dec,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-ghg .nrel-value#dec,
.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-fuel .nrel-value#dec {
  color: green;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-value#vcl {
  color: black;
  font-weight: 500;
}

.nrel-SteDashboard .nrel-overallStats-wrpr .nrel-value {
  display: inline-block;
  color: grey;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-left: 5px;
}

.nrel-SteDashboard .nrel-sensitivity-analysis-controls .nrel-value {
  display: inline-block;
  color: grey;
  font-size: 1.4rem;
  margin-top: 0.5rem;
  margin-left: 5px;
}

/* TABS */

.nrel-SteDashboard .nrel-tab-wrapper {
  display: grid;
  grid-area: tabs;
  margin-top: 2.5rem;
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 5%;
  width: 100%;
}

.nrel-SteDashboard .tab-content {
  background-color: white;
  min-height: 36rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 3px grey;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nrel-SteDashboard .tab-list {
  margin-left: 0.5;
  margin-right: 10;
  border-radius: 4px;
  margin: 0px 4px 0px 4px;
}
.nrel-SteDashboard .tab-list-item,
.nrel-SteDashboard .tab-list-item-deactive {
  color: rgb(80, 80, 80);
  background-color: rgb(223, 230, 237);
  font-size: 0.72rem;
  display: inline-block;
  list-style: none;
  width: auto;
  min-width: 3rem;
  border-left: 0.5px solid rgb(114, 112, 112);
  border-right: 0.5px solid rgb(114, 112, 112);
  border-top: 0.5px solid rgb(114, 112, 112);
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  border-radius: 9px 9px 0px 0px;
  text-align: center;
  box-shadow: 5px 0px 3px grey;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom: 1px solid rgb(136, 133, 133);
}
.nrel-SteDashboard .tab-list-item#Glossary,
.nrel-SteDashboard .tab-list-item#About,
.nrel-SteDashboard .tab-list-item#Settings {
  background-color: rgb(184, 180, 180);
  color: white;
}

.nrel-SteDashboard .tab-list-item-deactive {
  color: rgb(202, 200, 200);
  background-color: rgb(235, 236, 238);
}

.nrel-SteDashboard .css-1hwfws3 {
  overflow: scroll;
  max-height: 90px;
}

.nrel-SteDashboard .tab-list-active {
  background-color: white !important;
  color: rgb(71, 72, 74) !important;
  border: solid rgb(85, 84, 84);
  border-radius: 9px 9px 0px 0px;
  border-width: 1px 1px 0 1px;
}
.nrel-SteDashboard .nrel-viewGraph {
  position: absolute;
  right: 28%;
  top: 9.5rem;
  height: 40px;
  width: 165px;
  color: rgb(116, 120, 125);
  font-size: 1rem;
  border-radius: 0.1rem;
  border: 1px solid rgb(116, 120, 125);
  background-color: white;
  border-radius: 1rem;
}

/* About Section */
.nrel-glossary-wrapper,
.nrel-about-wrapper {
  position: relative;
  height: 34rem;
  margin: auto;
  background-color: white;
  border-radius: 0.25rem;
  /* margin-top: 2rem; */
}

.nrel-about#contact-header,
.nrel-about#company,
.nrel-about#name {
  margin-left: 2rem;
  color: rgb(109, 110, 112);
  font-size: 0.8rem;
}

.nrel-about#title {
  text-align: left;
  margin: 0rem;
  margin-top: 1.5rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  color: rgb(109, 110, 112);
  font-weight: 500;
  font-size: 1.3rem;
  float: left;
  width: 100%;
}

.nrel-about#email {
  margin-left: 2rem;
  font-size: 0.8rem;
  text-decoration: none;
}

.nrel-about#email:hover {
  text-decoration: underline;
}

.nrel-about#ph1,
.nrel-about#ph2 {
  padding-top: 0px;
  margin-top: 0px;
  margin-right: 4%;
  font-size: 0.8rem;
  text-align: justify;
}

.nrel-about#contact-header {
  font-size: 0.9rem;
  margin-top: 2rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}
.nrel-about#company {
  font-weight: bold;
  padding: 0rem;
  margin-top: 1rem;
  margin-bottom: 0rem;
  font-size: 0.8rem;
}

.nrel-about#name {
  margin-top: 2px;
  margin-bottom: 2px;
}

.nrel-SteDashboard .nrel-nav-stats-wrapper {
  grid-area: stats;
  position: absolute;
  right: 4rem;
}
.nrel-SteDashboard .nrel-nav-button-wrapper {
  margin-left: 20%;
  display: inline;
}
.nrel-SteDashboard .nrel-about-button,
.nrel-SteDashboard .nrel-assumptions-button {
  height: 40px;
  width: 145px;
  color: rgb(116, 120, 125);
  font-size: 1rem;
  border-radius: 0.1rem;
  border: 1px solid rgb(116, 120, 125);
  background-color: white;
  border-radius: 1rem;
  margin: 1rem;
}

.nrel-glossary-header {
  text-align: left;
  margin: 0rem;
  margin-top: 1.5rem;
  margin-left: 2rem;
  color: rgb(109, 110, 112);
  font-weight: 500;
  font-size: 1.3rem;
  float: left;
}

.nrel-glossary-text,
.nrel-about-text {
  color: grey;
  font-size: 0.75rem;
  text-align: center;
}

.nrel-glossary-list-wrapper {
  padding-top: 2.5rem;
}

.nrel-glossary-list {
  padding-left: 2rem;
}

.nrel-glossary-list-wrapper ul {
  list-style-type: none;
}

.nrel-glossary-item {
  padding: 6.5px;
  padding-bottom: 10px;
  font-size: 0.75rem;
  margin-right: 2rem;
  text-align: justify;
  color: grey;
  display: grid;
  grid-template-columns: 10rem auto;
  width: 96%;
}

.nrel-glossary-item:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}

.nrel-glossary-item #term {
  font-weight: 500;
  text-align: left;
  padding-right: 10px;
}

.nrel-SteDashboard .nrel-mapTab {
  min-height: 40rem;
  position: relative;
}

.nrel-map-loading {
  border: 1px solid grey;
  margin-left: 2rem;
  margin-right: 2rem;
}

.nrel-SteDashboard .legend {
  text-align: left;
  line-height: 22px;
  color: #555;
  background-color: rgba(250, 248, 248, 0.822);
  border: 1px solid rgb(160, 159, 159);
  padding: 5px;
  border-radius: 0.25rem;
}

.nrel-SteDashboard .legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
  border-radius: 1rem;
}

.nrel-SteDashboard .legend h3 {
  margin: 0rem;
  margin-bottom: -0.6rem;
  font-size: 0.7rem;
  text-decoration: underline;
}

.nrel-map-loading .nrel-loading-background {
  margin-left: 32%;
}

.nrel-homeTab-description,
.nrel-mapTab-description {
  font-size: 0.75rem;
  color: grey;
  margin-left: 2rem;
  margin-right: 2rem;
  grid-area: notes;
  margin-top: 0rem;
}

/* TABLE */
.nrel-SteDashboard .nrel-ezevRecs {
  position: relative;
  display: grid;
  grid-template-rows: 12% 10% 78%;
  grid-template-columns: 85% 15%;
  grid-template-areas:
    "title title"
    "buttons csv"
    "table table";
  width: 99%;
  margin-right: 5%;
  min-height: 30rem;
  height: 45rem;
}

.nrel-SteDashboard .nrel-ezevRecs .title-container {
  grid-area: title;
}
.nrel-SteDashboard .nrel-ezevRecs .title-container p {
  color: grey;
  margin-left: 2rem;
  font-size: 0.75rem;
}

.nrel-ezev-radio-btns-title {
  grid-area: title;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-weight: normal;
  color: grey;
  font-weight: 450;
}
.nrel-ezev-radio-btns {
  border-radius: 0.25rem;
  margin-top: 18px;
  margin-left: 2rem;
  min-height: 54px;
  padding-top: 2px;
  display: grid;
  grid-template-rows: 25% 75%;
  grid-template-columns: 20% 25% 30% 25%;
  grid-template-areas:
    "title title title title"
    "option option option option";
  text-align: center;
  border: 1px solid grey;
}
.nrel-ezev-btn-label {
  padding-top: 5px;
  height: 30px;
  font-size: 0.75rem;
  color: rgb(90, 90, 90);
  vertical-align: top;
  cursor: pointer;
}

.nrel-ezev-radio-btns input {
  margin-right: 6px;
  margin-top: 2px;
  height: 12px;
}

.nrel-SteDashboard .nrel-recsTable-wrpr {
  grid-area: table;
  overflow: scroll;
  max-height: 33.5rem;
  margin-top: 0.5rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-left: 2rem;
}

.nrel-recsTable-wrpr .nrel-loading {
  margin-left: 30%;
}

.nrel-SteDashboard .nrel-ezevRecs .nrel-table {
  max-height: 32rem;
  overflow-y: scroll;
  margin-bottom: 2rem;
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px 4px 0 0;
}

.nrel-SteDashboard .nrel-ezevRecs .nrel-table th {
  padding-top: 1rem;
}

.nrel-SteDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-both,
.nrel-SteDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-desc,
.nrel-SteDashboard .nrel-table-header .swt-sort-arrows .swt-sort-arrow-asc {
  width: 7px;
}

.nrel-SteDashboard .nrel-table-header .AssetID,
.nrel-SteDashboard .nrel-table-header .Year,
.nrel-SteDashboard .nrel-table-header .Make,
.nrel-SteDashboard .nrel-table-header .Model,
.nrel-SteDashboard .nrel-table-header .ezEVRec,
.nrel-SteDashboard .nrel-table-header .ezEVScore,
.nrel-SteDashboard .nrel-table-header .Location,
.nrel-SteDashboard .nrel-table-header .NumbOfEVs,
.nrel-SteDashboard .nrel-table-header .maxKW,
.nrel-SteDashboard .nrel-table-header .time,
.nrel-SteDashboard .nrel-table-header .DailyMaxKW,
.nrel-SteDashboard .nrel-table-header .PeakTime,
.nrel-SteDashboard .nrel-table-header .Class,
.nrel-SteDashboard .nrel-table-header .Price,
.nrel-SteDashboard .nrel-table-header .BatteryCap,
.nrel-SteDashboard .nrel-table-header .MPG,
.nrel-SteDashboard .nrel-table-header .ModalMaxKW,
.nrel-SteDashboard .nrel-table-header .ReasonOfFailure,
.nrel-SteDashboard .nrel-table-header .BestInClassOverall,
.nrel-SteDashboard .nrel-table-header .BestInClassYMM,
.nrel-SteDashboard .nrel-table-header .BestInClassReasonFailure,
.nrel-SteDashboard .nrel-table-header .lvl2ChargingHrs {
  background-color: #72afc4;
  color: black;
  font-size: 0.8rem;
  font-weight: 450;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  background-position: right 5px center;
  position: sticky;
  top: 0rem;
}
.nrel-SteDashboard .swt-sort-arrows {
  margin-left: 5%;
}
.nrel-SteDashboard .nrel-percentView .nrel-table {
  width: 100%;
}

.nrel-SteDashboard .nrel-percentView .no-results-message {
  border: 1px solid grey;
  margin-left: 2rem;
  margin-right: 2rem;
  grid-area: table;
  color: grey;
}

.nrel-SteDashboard .nrel-percentView .no-results-message p {
  text-align: center;
  margin-top: 6%;
  font-size: 2rem;
}

.nrel-SteDashboard .nrel-percentView .nrel-table-header {
  height: 0rem;
}
.nrel-SteDashboard .nrel-percentView .cell#NumbOfEVs,
.nrel-SteDashboard .nrel-percentView .cell#maxKW,
.nrel-SteDashboard .nrel-percentView .cell#time {
  text-align: center;
}

.nrel-SteDashboard .nrel-table-header .asset_id {
  width: 25%;
}
.nrel-SteDashboard .nrel-table-header .Year {
  width: 8%;
}
.nrel-SteDashboard .nrel-table-header .Make {
  width: 10%;
}
.nrel-SteDashboard .nrel-table-header .Model {
  width: 10%;
}
.nrel-SteDashboard .nrel-table-header .ezEVRec {
  width: 20%;
}
.nrel-SteDashboard .nrel-table-header .ezEVScore {
  width: 6%;
}
.nrel-SteDashboard .nrel-table-header .ReasonOfFailure {
  width: 20%;
}

.nrel-SteDashboard .nrel-table-header .Location {
  width: 35%;
}

.nrel-SteDashboard .nrel-percentView .nrel-table-header .evCount {
  width: 7%;
}
.nrel-SteDashboard .nrel-table-header .NumbOfEVs {
  width: 20%;
}
.nrel-SteDashboard .nrel-table-header .ModalMaxKW {
  width: 10%;
}
.nrel-SteDashboard .nrel-table-header .DailyMaxKW {
  width: 8%;
}

.nrel-SteDashboard .nrel-table-header .PeakTime {
  width: 22%;
}
.nrel-SteDashboard .nrel-table-header .YMM,
.nrel-SteDashboard .nrel-table-header .AssetID,
.nrel-SteDashboard .nrel-table-header .Year,
.nrel-SteDashboard .nrel-table-header .Make,
.nrel-SteDashboard .nrel-table-header .Model,
.nrel-SteDashboard .nrel-table-header .ezEVRec,
.nrel-SteDashboard .nrel-table-header .ezEVScore,
.nrel-SteDashboard .nrel-table-header .ReasonOfFailure {
  position: sticky;
  top: 0px;
}

/* end */

.nrel-SteDashboard .nrel-download-btn {
  grid-area: csv;
  margin-left: auto;
  margin-right: 2rem;
  margin-top: 2%;
  background-color: rgba(19, 144, 139);
  border: 1px solid white;
  color: white;
  border-radius: 0rem;
  height: 35px;
  width: 120px;
}

.nrel-SteDashboard .nrel-download-btn p {
  padding-left: 16px;
  margin-bottom: 20px;
  margin-top: 0.6rem;
  text-decoration: none;
  font-size: 0.8rem;
}

.nrel-SteDashboard .nrel-download-btn#ezevRecs {
  margin-left: auto;
  margin-right: 0rem;
  margin-top: 2.4rem;
  margin-bottom: 0rem;
}

.nrel-SteDashboard .nrel-download-btn#perc {
  top: 0rem;
  right: 6%;
  grid-area: csv;
}

.nrel-SteDashboard .nrel-download-btn#settings {
  margin-top: 1rem;
  margin-left: 75%;
}
/* MAP */

.nrel-SteDashboard .nrel-map {
  position: relative;
  margin-top: 0rem;
  min-width: 31.063rem;
  min-height: 21.313rem;
  height: 70%;
  border: 1px solid grey;
  overflow: hidden;
  border-radius: 4px;
}

.marker-cluster {
  color: grey;
  background-color: rgba(128, 128, 128, 0.267);
  opacity: 40%;
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.527);
}

.marker-cluster:hover {
  background-color: rgba(0, 140, 255, 0.63);
}
.marker-cluster.small {
  color: rgba(79, 76, 241, 0.678);
}

.marker-cluster.medium {
  color: rgb(30, 94, 190);
}

.marker-cluster.large {
  color: rgb(31, 60, 190);
}

.marker-cluster div {
  margin-left: 40%;
  margin-top: 20%;
  font-weight: bold;
}

.nrel-SteDashboard .nrel-map .nrel-loading {
  margin-top: 1%;
  margin-left: 35%;
  height: 5rem;
}

.nrel-map-popup-bold {
  color: rgb(104, 103, 103);
}

.nrel-SteDashboard .nrel-mapTab .nrel-map {
  height: 30rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.nrel-SteDashboard .nrel-percLegendItem {
  padding-left: 29%;
}

.nrel-SteDashboard .leaflet-popup-content p {
  margin: 0rem;
}

.nrel-SteDashboard .leaflet-popup-content span {
  font-weight: 500;
}

.nrel-SteDashboard .leaflet-popup-content h1 {
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.nrel-SteDashboard .nrel-symbology-selector {
  background-color: white;
  border: 1px solid black;
  border-bottom: none;
  height: 3.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 1rem;
  border-radius: 0.4rem 0.4rem 0 0;
  display: grid;
  grid-template-columns: 50% 50%;
}

.nrel-map-percent-selector {
  display: inline;
  border-right: 1px solid rgb(201, 200, 200);
}
.nrel-SteDashboard .nrel-map-vclCount,
.nrel-SteDashboard .nrel-legend-label {
  color: rgb(109, 110, 112);
  float: left;
  margin-left: 25%;
  margin-right: 2rem;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.nrel-SteDashboard .nrel-symbology-selector .nrel-group-selector#percent {
  color: rgb(109, 110, 112);
  margin-top: 1rem;
  width: 5rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
}

/* END */
.nrel-glossary-header,
.nrel-about-header,
.nrel-charging-graph-ttl,
.nrel-SteDashboard .nrel-ezevRecs-headers,
.nrel-SteDashboard .home-header,
.nrel-SteDashboard .percView-headers {
  text-align: left;
  margin: 0rem;
  margin-top: 1rem;
  margin-left: 2rem;
  color: grey;
  font-weight: 500;
  font-size: 1.3rem;
  float: left;
  width: 100%;
}

/*   Percentage Pages */

.nrel-SteDashboard .nrel-ezevRecs-headers {
  grid-area: title;
}

.nrel-SteDashboard .nrel-percentView {
  position: relative;
  width: 100%;
  /* margin-top: 0.5rem; */
  height: 48rem;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 17% 28% 56%;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  grid-template-areas:
    "title title"
    "table table"
    "map map";
}

.nrel-SteDashboard .nrel-percentView .nrel-group-selector#percent {
  color: grey;
  font-weight: bold;
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid grey;
  width: fit-content;
}

.nrel-SteDashboard .nrel-percentView .nrel-group-selector#percent:active {
  color: grey;
  font-weight: bold;
  font-size: 0.8rem;
  border: none;
  width: 4.5rem;
}

.nrel-SteDashboard .nrel-percView-wrapper {
  grid-area: title;
  display: grid;
  grid-template-areas:
    "title csv"
    "notes notes";
  grid-template-columns: 73% 27%;

  width: 100%;
}

.nrel-SteDashboard .nrel-percent-table-wrapper .nrel-loading-background {
  background-color: rgba(228, 226, 226, 0.719);
  position: absolute;
  height: 82.5%;
  width: -webkit-fill-available;
  z-index: 1001;
  margin-right: 4.1rem;
  border-radius: 0.25rem;
}

.nrel-SteDashboard .nrel-percent-table-wrapper .nrel-loading-background svg {
  margin-top: 10rem;
}

.nrel-SteDashboard .nrel-percView-wrapper .nrel-loading {
  margin-left: auto;
  margin-right: auto;
}

.nrel-SteDashboard .nrel-percentView .percView-headers {
  width: 100%;
  margin-top: 1.8rem;
}
.nrel-SteDashboard .nrel-mapTab .percView-headers {
  margin-bottom: 0.2rem;
}

.nrel-SteDashboard .nrel-percentView .nrel-download-btn#perc {
  grid-area: csv;
  margin-top: 0.8rem;
}
.nrel-SteDashboard .nrel-map-graph-link {
  color: black;
  text-decoration: underline;
  margin-top: 1rem;
  cursor: pointer;
}

.nrel-SteDashboard .nrel-map-graph-link:hover {
  color: blue;
}

.nrel-percent-table-wrapper {
  grid-area: table;
  height: 12.45rem;
  overflow: scroll;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 4px;
  border: 1px solid grey;
}

.nrel-percent-table-wrapper .nrel-loading-background .nrel-loading {
  margin-left: 40%;
}
.nrel-SteDashboard .nrel-percentView .nrel-table {
  border-collapse: collapse;
}

.nrel-SteDashboard .nrel-percentView .nrel-table-header th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  position: sticky;
  top: 0rem;
}

.nrel-SteDashboard .nrel-percentView .nrel-map-controller {
  width: 100%;
  grid-area: map;
  position: relative;
  height: 21.5rem;
  margin-top: 0rem;
}

.nrel-SteDashboard .nrel-percentView .nrel-map-controller .nrel-map {
  height: 22rem;
  position: relative;
  min-height: 21rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.nrel-SteDashboard .nrel-dash-landing {
  display: grid;
  position: relative;
  grid-template-areas:
    "title title"
    "info stats"
    "tabs tabs";
  grid-template-rows: 8rem 10rem 73%;
  grid-template-columns: 30% 70%;
  /* height: 65rem; */
  width: 90%;
  overflow: hidden;
  min-width: 890px;
  padding-bottom: 1rem;
  margin-left: 7rem;
}

.nrel-SteDashboard .nrel-title-container {
  display: grid;
  grid-area: title;
  position: relative;
}

.nrel-SteDashboard .nrel-link {
  position: absolute;
  top: 5.5rem;
  float: right;
  width: 6rem;
}

.nrel-SteDashboard .nrel-assumptions-page {
  display: grid;
  grid-template-areas:
    "title csv"
    "description description"
    "assumptions assumptions"
    "bev bev"
    "phev phev"
    "ice ice";
  border-radius: 10px;
  min-height: 40rem;
  /* grid-template-rows: 5rem 10rem 30rem 30rem 30rem; */
}

.nrel-assumptions-description {
  grid-area: description;
  color: grey;
  font-size: 0.8rem;
  padding-left: 2rem;
  margin: 0rem;
  margin-bottom: 0.5rem;
  width: fit-content;
}

.nrel-SteDashboard .nrel-divider {
  width: 21rem;
  height: 0;
  border: solid 0.25px rgba(128, 128, 128, 0.397);
}

.nrel-SteDashboard .nrel-assumption {
  margin: 0.25rem;
  font-weight: 500;
  font-size: 0.8rem;
}

.nrel-SteDashboard .nrel-assumptions-title {
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-left: 2rem;
  font-size: 1.3rem;
  color: grey;
  font-weight: 500;
  text-align: left;
}

.nrel-SteDashboard .nrel-table-text-right-aligned {
  text-align: right;
  color: grey;
  font-weight: 250;
}
.nrel-SteDashboard .nrel-information-card {
  min-width: 40rem;
  background-color: rgb(223, 230, 237);
  height: 7rem;
  border-radius: 0.5rem;
  padding: 2rem;
  padding-bottom: 0rem;
  margin-top: 0.05rem;
  margin-left: 2rem;
  margin-right: 2rem;
  grid-area: assumptions;
}
.nrel-SteDashboard .nrel-information-card .nrel-card-content-l {
  float: left;
  width: 25rem;
}
.nrel-SteDashboard .nrel-information-card .nrel-card-content-r {
  float: right;
  width: 25rem;
  /* margin-right: 1%; */
}
.nrel-SteDashboard .nrel-assumptions-page .nrel-table {
  width: 100%;
  border-collapse: collapse;
  border-width: 0px;
  margin-bottom: 0.5rem;
}

.nrel-SteDashboard .nrel-assumptions-page .nrel-assumption-header {
  background-color: #72afc4;
  margin: 0rem;
  padding: 0.5rem;
  font-weight: 500;
  border-radius: 4px 4px 0 0;
  font-size: 18px;
  position: sticky;
  top: 0px;
}

.nrel-SteDashboard .nrel-table td {
  display: inline-block;
  display: table-cell;
  flex: 1 0;
  white-space: normal;
  padding: 15px 5px 7px;
  transition: 0.3s ease;
  font-size: 14px;
  overflow: hidden;
  transition-property: width, min-width, padding, opacity;
  text-align: left;
}

.nrel-SteDashboard .nrel-table tr:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}

.nrel-SteDashboard .nrel-ezevRecs .nrel-table td:nth-child(5) {
  background-color: rgba(221, 236, 236, 0.55);
}

.nrel-SteDashboard .nrel-assumptions-page .Model {
  width: 35%;
}

.nrel-SteDashboard .nrel-assumptions-page .nrel-table {
  max-height: 10rem;
  overflow: scroll;
}

.nrel-SteDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr {
  max-height: 30rem;
  overflow: scroll;
  margin-bottom: 1%;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.4rem;
}
.nrel-SteDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#ice {
  grid-area: ice;
  border: 1px solid grey;
}
.nrel-SteDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#bev {
  margin-top: 0.5rem;
  grid-area: bev;
  border: 1px solid grey;
}

.nrel-SteDashboard .nrel-assumptions-page .nrel-assumption-table-wrpr#phev {
  grid-area: phev;
  border: 1px solid grey;
}

.nrel-SteDashboard .nrel-assumption-table-wrpr .Model,
.nrel-SteDashboard .nrel-assumption-table-wrpr .Class,
.nrel-SteDashboard .nrel-assumption-table-wrpr .Price,
.nrel-SteDashboard .nrel-assumption-table-wrpr .BatteryCap,
.nrel-SteDashboard .nrel-assumption-table-wrpr .MPG {
  position: sticky;
  top: 31px;
}
.nrel-graph-table-wrapper {
  padding: 10px;
  display: grid;
  grid-template-columns: 70% 30%;
}

.nrel-location-vehicle-wrapper {
  border: 1px solid grey;
  border-radius: 0.25rem;
  height: 30.7rem;
  margin-top: 0rem;
  padding: 2.5px;
  box-shadow: 5px 5px 3px grey;
  position: relative;
}

.nrel-location-vehicle-wrapper .nrel-download-btn p {
  background-color: rgba(19, 144, 139);
  border: 1px solid white;
  border-radius: 0.25rem;
  color: white;
  border-radius: 0rem;
  height: auto;
  padding-top: 5px;
  padding-bottom: 8px;
  width: 105px;
  margin-top: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.nrel-graph-table-wrapper#monthly .nrel-location-vehicle-wrapper {
  height: 32.7rem;
}
.nrel-location-vehicle-wrapper th,
.nrel-location-vehicle-wrapper .nrel-table-text .lvl2ChargingHrs,
.nrel-location-vehicle-wrapper .nrel-table-text .AssetID {
  background-color: rgb(87, 151, 182);
  height: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.7rem;
}

.nrel-location-vehicle-wrapper .nrel-table {
  width: 100%;
}
.nrel-location-vehicle-wrapper .nrel-table td {
  font-size: 12px;
}

.nrel-location-vehicle-wrapper .nrel-vehicle-table-wrapper {
  height: 27rem;
  overflow: scroll;
  border: 1px solid rgb(185, 184, 184);
}

.nrel-graph-table-wrapper#daily .nrel-vehicle-table-wrapper {
  height: 25rem;
}

.nrel-graph-wrapper#daily {
  height: 51rem;
}
.nrel-location-vehicle-title {
  color: grey;
  font-size: 0.9rem;
  width: 100%;
  margin: 0.25rem;
  text-align: center;
}

.nrel-underline {
  text-decoration: underline;
}
.nrel-graph-wrapper {
  position: relative;
  height: 51rem;
}
.nrel-graph-control-wrapper {
  display: inline-block;
}

.nrel-graph-explanation {
  color: grey;
  margin: 3rem 1rem 0rem 1rem;
  font-size: 0.6rem;
  text-align: justify;
  border: 1px solid rgb(175, 172, 172);
  border-radius: 0.25rem;
  background-color: rgb(243, 241, 241);
}

.nrel-daily-graph-wrapper .nrel-graph-explanation {
  margin: 1rem 1rem 0rem 1rem;
}

.nrel-graph-explanation a {
  text-decoration: none;
}

.nrel-graph-explanation a:hover {
  text-decoration: underline;
}

.nrel-graph-explanation p {
  margin: 0.5rem;
}

.nrel-graph-controls-monthly,
.nrel-graph-controls-daily {
  margin: 0rem 2rem 1rem 2rem;
  border: 1px solid rgb(231, 229, 231);
  border-top: none;
  padding: 10px;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  background-color: rgba(231, 229, 231, 0.295);
  display: grid;
}
.nrel-graph-controls-monthly#totalFleet {
  grid-template-columns: 50% 50%;
}

.nrel-graph-controls-daily#totalFleet {
  grid-template-columns: 20% 40% 16% 24%;
}

.nrel-graph-controls-daily#ezEvResults {
  grid-template-columns: 25% 50% 25%;
}

.nrel-SteDashboard .nrel-graph-back-button {
  background-color: white;
  border: 1px solid grey;
  padding: 5px;
  max-width: 10rem;
  border-radius: 0.25rem;
  color: rgb(82, 81, 81);
  margin: 5px 8px 0px 0px;
}

.nrel-graph-controls-monthly .nrel-group-selector,
.nrel-graph-controls-daily .nrel-group-selector {
  background-color: white;
  border-radius: 0.25rem;
  height: 2rem;
  color: rgb(73, 72, 72);
  margin: auto;
}

.nrel-graph-controls-monthly .nrel-group-selector#locations,
.nrel-graph-controls-daily .nrel-group-selector#locations {
  width: 80%;
}

.nrel-graph-controls-monthly#ezEvResults .nrel-group-selector#locations {
  width: 50%;
}

.nrel-graph-wrapper .nrel-graph-control-wrapper,
.nrel-graph-wrapper .nrel-graph-control-wrapper-daily {
  justify-content: center;
  text-align: center;
}

.nrel-SteDashboard .nrel-charge-text-wrapper {
  background-color: rgba(231, 229, 231, 0.295);
  border: 1px solid rgb(231, 229, 231);
  border-radius: 0.25rem;
  margin: 0.25rem 2rem 0.25rem 2rem;
  text-align: left;
  height: auto;
}

.nrel-SteDashboard .nrel-charging-text {
  color: grey;
  margin-left: 2rem;
  padding: 0.25rem;
  padding-left: 1rem;
  font-size: 0.7rem;
  margin: 0rem;
  padding-top: 0.25rem;
  text-align: justify;
}

.nrel-SteDashboard .nrel-charging-text .nrel-text-bold {
  font-weight: bold;
  font-size: 0.9rem;
}

.nrel-SteDashboard .nrel-charging-graph {
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin: 1%;
  width: 98%;
  padding-bottom: 0.25rem;
  height: 96%;
  position: relative;
  overflow: hidden;
}

.nrel-graph-legend {
  width: 50%;
  margin-left: 10%;
}
.nrel-graph-legend#daily {
  display: grid;
  grid-template-columns: 50% 50%;
}
.nrel-graph-legend .legend-item {
  width: fit-content;
  margin: auto;
  margin-top: 0rem;
}

.legend-item .nrel-legend-text {
  float: right;
  padding-top: 0.25rem;
  font-size: 11.5px;
  padding-left: 0.5rem;
}

.nrel-graph-legend .nrel-peak-kw {
  float: left;
  margin-top: 0.2rem;
  border-radius: 0.2rem;
  height: 1rem;
  width: 2.25rem;
  background-color: black;
  /* margin-left: 46%; */
}

.nrel-graph-legend .nrel-peak-kw#monthly {
  background-color: rgba(87, 151, 182, 0.6);
  border: 1px solid rgb(87, 151, 182);
}

.nrel-graph-legend .nrel-peak-kw#top {
  background-color: rgba(255, 63, 51, 0.4);
  border: 1px solid red;
}
.nrel-graph-legend .nrel-peak-kw#other {
  background-color: rgba(178, 177, 175, 0.8);
  border: 1px solid grey;
}

.nrel-graph-legend p {
  font-weight: 500;
  color: grey;
  margin: 0px;
}
.nrel-SteDashboard .nrel-legend-footnote {
  position: absolute;
  left: 40%;
  height: 1rem;
  color: grey;
  bottom: 0rem;
  padding-bottom: 0.25rem;
}

.nrel-controls-text {
  width: fit-content;
  text-align: center;
  font-weight: 500;
  font-size: 0.8rem;
  margin: auto;
  color: grey;
}

.nrel-noVcls-message {
  color: grey;
  text-decoration: underline;
  font-size: 0.8rem;
  margin-top: 5%;
  border: 1px solid rgba(128, 128, 128, 0.671);
  position: absolute;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  background-color: white;
  border-radius: 0.25rem;
  width: 30rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  left: 0;
  right: 0;
}

.nrel-chargeGraph-summarizer {
  padding: 0rem 2rem 0rem 2rem;
  border-bottom: 1px solid rgb(231, 229, 231);
  /* width: 86.5%; */
  /* margin-left: 3.5%; */
  margin-bottom: 0.75rem;
  display: grid;
  grid-template-columns: 47.5% 17.5% 35%;
  height: 1.25rem;
  overflow: hidden;
}

.nrel-chargeGraph-wrapper {
  width: 94%;
  border: 1px solid rgb(231, 229, 231);
  border-radius: 0.25rem;
  position: relative;
  margin-left: 3%;
  height: 26rem;
  margin-top: 0.5rem;
  overflow: hidden;
}

.nrel-graph-container#daily {
  position: relative;
  height: 22rem;
  margin-right: 2rem;
  margin-bottom: 0.75rem;
}
.nrel-graph-container#monthly {
  position: relative;
  height: 24rem;
  margin-bottom: 0rem;
  margin-right: 2rem;
}
.nrel-daily-graph-wrapper,
.nrel-monthly-graph-wrapper {
  border: 1px solid grey;
  padding-top: 1rem;
  border-radius: 0.25rem;
  margin-right: 5px;
  box-shadow: 2px 5px 3px grey;
}

.nrel-daily-graph-wrapper {
  height: 30rem;
}

.nrel-monthly-graph-wrapper {
  height: 32rem;
}

.nrel-summarizer-text {
  font-size: 0.75rem;
  margin: 0rem;
  color: grey;
  font-weight: 500;
}

.nrel-summarizer-text#vcl-ct {
  text-align: right;
}

.nrel-summarizer-value {
  font-size: 1rem;
  font-weight: bold;
  color: rgb(95, 94, 94);
}

.nrel-SteDashboard .nrel-loading-wrapper {
  margin-left: 30%;
}

.nrel-underline {
  text-decoration: underline;
}

.nrel-SteDashboard .nrel-charging-graph-instructions {
  color: grey;
  margin: 0.4rem 2rem 0rem 2rem;
  font-size: 0.75rem;
  text-align: justify;
}

.nrel-SteDashboard .nrel-footnote-wrpr {
  background-color: white;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: 1px solid grey;
  margin-top: -0.3rem;
  box-shadow: 5px 5px 3px grey;
  z-index: 2;
}

.nrel-footnote-title {
  color: grey;
  margin: 0.5rem 0.5rem 0.2rem 2rem;
  text-decoration: underline;
  text-justify: left !important;
  font-size: 0.8rem;
}

.nrel-footnote {
  font-size: 0.75rem;
  color: grey;
  margin: 0.5rem;
  margin-left: 2rem;
}

.nrel-toggle-wrapper {
  grid-area: toggle;
  display: grid;
  grid-template-columns: 45% 10% 45%;
  border: 1px solid rgb(231, 229, 231);
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  background-color: rgba(231, 229, 231, 0.295);
  margin: 0.5rem 2rem 0rem 2rem;
  padding-bottom: 0.25rem;
}

.nrel-toggle-wrapper .nrel-toggle-selector-title {
  margin-left: 1rem;
  margin-top: 0.6rem;
  font-size: 0.8rem;
  color: grey;
}

.nrel-toggle-wrapper .nrel-toggle-selector-title#ezev {
  text-align: left;
  width: fit-content;
}

.nrel-toggle-wrapper .nrel-toggle-selector-title#all {
  text-align: right;
  width: fit-content;
  margin-left: auto;
}

.nrel-toggle-wrapper .react-toggle-track {
  height: 0.78rem;
  width: 2.8rem;
  margin-top: 0.4rem;
}
.nrel-toggle-wrapper .react-toggle-thumb {
  height: 1.25rem;
  width: 1.25rem;
  margin-top: 0.1rem;
}

.nrel-toggle-container {
  margin-top: 0.75rem;
  text-align: center;
  transform: rotate(180deg);
}

.nrel-onHover-explanation {
  position: absolute;
  top: 0;
  left: 10rem;
  color: rgb(109, 110, 112);
  background-color: rgba(255, 255, 255, 0.942);
  height: auto;
  max-width: 20rem;
  z-index: 5;
  border: 1px solid grey;
  border-radius: 0.25rem;
  -webkit-box-shadow: 3px 5px 15px -2px #000000;
  box-shadow: 3px 5px 15px -2px #000000;
}

.nrel-onHover-title {
  margin: 0px;
  margin-top: 2px;
  margin-left: 5px;
  font-weight: bold;
}

.nrel-onHover-message {
  margin: 5px;
}

.nrel-hover-icon {
  /* color: rgb(78, 78, 78); */
  font-size: 11px;
}

.evse-note {
  color: grey;
  font-size: 0.5rem;
  margin-top: 0.9rem;
  margin-left: 0.5rem;
}

/* Home tab styling */

.nrel-SteDashboard .nrel-homeTab-description {
  margin-left: 1rem;
}

.nrel-SteDashboard .home-wrapper {
  background-color: rgb(247, 247, 248);
  border: 1px solid rgb(219, 218, 218);
  margin: 1rem;
  border-radius: 0.25rem;
  margin-top: 1.25rem;
  padding: 10px;
  padding-bottom: 20px;
}

.nrel-sub-header {
  padding-top: 0.25rem;
  margin-top: 0rem;
  line-height: 1rem;
  font-size: 1rem;
  color: grey;
  margin-left: 0.5rem;
  margin-bottom: 0.8rem;
}

.nrel-tool-list-describer {
  font-size: 0.9rem;
  color: grey;
  margin-left: 1rem;
  margin-bottom: 0rem;
}

.nrel-tool-list {
  color: grey;
  margin-top: 0.25rem;
  line-height: 15px;
  margin-left: 0.25rem;
  font-size: 0.7rem;
}

.about-section {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
}
.about-section:nth-child(odd) {
  background-color: rgb(223, 230, 237);
  padding: 5px;
}

.home-wrapper span {
  font-weight: bold;
}

.nrel-SteDashboard .home-wrapper ul {
  margin-left: 0.5rem;
}

.about-section#note {
  background-color: white;
  margin: 0rem;
}

.about-section#note h4 {
  margin-left: 1rem;
  margin-bottom: 0rem;
  font-size: 0.8rem;
}

.about-section#note p {
  font-size: 0.6rem;
  margin-top: 0rem;
  margin-bottom: 1rem;
}

.nrel-SteDashboard .additional-resources {
  background-color: rgb(223, 230, 237);
  margin: 1rem;
  margin-top: 4rem;
  border-radius: 0.25rem;
  border: 1px solid rgba(128, 128, 128, 0.411);
}

.nrel-SteDashboard .additional-resources h4 {
  color: rgb(109, 110, 112);
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.nrel-SteDashboard .additional-resources .section-title {
  color: rgb(109, 110, 112);
  font-size: 0.75rem;
  margin-left: 0.7rem;
  margin-bottom: 0.25rem;
  display: inline-block;
}

.nrel-SteDashboard .additional-resources .section-title#link {
  margin-left: 0rem;
}

.nrel-SteDashboard .additional-resources ul a {
  text-decoration: none;
  font-size: 0.7rem;
}

.nrel-SteDashboard .additional-resources ul a:hover {
  text-decoration: underline;
}

.nrel-SteDashboard .additional-resources ul {
  margin-top: 3px;
}

.nrel-SteDashboard .additional-resources li {
  line-height: 1.1rem;
}

.nrel-SteDashboard .additional-resources li::marker {
  color: grey;
}

.nrel-SteDashboard .project-about {
  color: grey;
  margin: 2rem;
  font-size: 0.8rem;
  padding: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}
