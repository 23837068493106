
/* #selected Used for updating styles for active nav tab  */
#selected {
  border-bottom: none;
  background-color: #fff;
}

.swt-analytics-title {
  color: grey;
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.2rem;
}

.swt-charge-graph-wrapper {
  height: 28rem;
  width: 100%;
  border: 1px solid grey;
  border-radius: 0rem 0rem .25rem .25rem;
}

.swt-analytics-graph-wrpr {
  grid-area: loadGraph;
  height: 30rem;
  width: 95%;
  position: relative;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 2.5%;
  border: 1px solid grey;
  border-radius: 0.25rem;
}

.swt-analytics-lhs {
  width: 95%;
  margin: 2.5%;
  height: 10rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
  justify-content: center;
}

.swt-analytics-lhs {
  grid-area: ULTool;
}

.swt-analytics-lhs p{
  width: 100%;
  padding-top: 3rem;
  text-align: center;
  color: grey;
  margin: auto;
  font-weight: bold;
  font-size: 1rem;
}

.swt-analytics-segment-controls {
  display: grid;
  grid-template-columns: 27% 17.5% 17.5% 18% 20%;
  width: 100%;
  text-align: center;
  height: 1.8rem;
  border-bottom: 1px solid grey;
  padding-top: 0.5rem;
}

.dev-comparison{
  width: fit-content;
}

.swt-analytics-segment-controls#installedDevices {
  grid-template-columns: 20% 15% 15% 15% 15% 20%;
}

.swt-analytics-segment-controls#metricComparison {
  grid-template-columns: 15% 12.5% 12.5% 12.5% 33% 10%;
}

.swt-analytics-segment-controls label {
  font-size: 0.9rem;
}
.swt-analytics-segment-selector {
  height: 1.4rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
}

.swt-admin-analytics-submit {
  padding: 2px;
  border: 1px solid grey;
  width: 8rem;
  height: 1.5rem;
  border-radius: 0.25rem;
  background-color: white;
  margin-left: 15%;
}

.swt-devtools-row-selected{
  font-weight: 900;
  color: black;
}

.dev-tools-responsive-map-container{
    height: 350px;
    width: 100%;
    z-index: 0;
    border: 1px solid;
}

