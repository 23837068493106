.swt-modalWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(112, 111, 111, 0.425);
  z-index: 3000;
}

.swt-modalContainer {
  position: relative;
  width: 30rem;
  min-height: 15rem;
  height: fit-content;
  margin: auto;
  margin-top: 20%;
  margin-left: 34%;
  border-radius: 8px;
  background-color: white;
  border: 1px solid grey;
}

.swt-modalConfirmation {
  position: absolute;
  bottom: 0;
  margin-left: 45%;
  margin-bottom: 2rem;
  border: 1px solid grey;
  color: rgb(70, 69, 69);
  background-color: white;
  width: 7rem;
  height: 2.3rem;
  border-radius: 4px;
  color: grey;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.swt-modalConfirmation:active {
  color: black;
  border: 1px solid black;
}

.swt-modalConfirmation#confirm {
  margin-left: 51%;
}

.swt-modalConfirmation#cancel {
  margin-left: 23%;
}

.swt-modalTitle {
  font-size: 1.2rem;
  margin-top: 2rem;
  color: grey;
  text-align: center;
}

.swt-modalMessage {
  text-align: center;
  margin-top: 1rem;
  color: grey;
  font-size: .9rem;
}

.swt-modalConfirmationMessage {
  text-align: center;
  margin-top: 10%;
  font-size: .9rem;
  color: grey;
}
