/* Color palette */
@import url("https://fonts.googleapis.com/css?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

@font-face {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
  font-style: normal;
  font-weight: normal;
  src: "https://fonts.googleapis.com/css?family=Arimo&display=swap";
  src: "https://fonts.googleapis.com/css?family=Open+Sans&display=swap";
}

body {
  font-family: "Arimo", "Open Sans", "HelveticaNeue", "Helvetica Neue", Arial,
    sans-serif;
}

:root {
  --dark-grey: #2f2f30;
  /* --light-navy: #12365b; */
  --bluish: #259cc4;
  --charcoal-grey: #47484a;
  /* --very-light-blue: #e6eff7; */
  --deep-aqua: #0b6787;
  --battleship-grey: #74787d;
  /* --light-peach: #f7deba; */
  /* --dark-grey-blue: #37495b; */
  --light-grey-blue: #b6bcc1;
  --light-sky-blue: #d8e3ed;
  /* --light-blue-grey: #cdd1d4; */
  --dark-turquoise: #035e5b;
  --blue-green: #13908b;
  --dark-grey-two: #262627;
  /* --pale-grey: #f2f6fa; */
  --bluey-grey: #979a9e;
  --muted-blue: #72afc4;
  --light-grey: #f5f5f5;
}

.swt-landing-logo {
  position: absolute;
  left: calc(50vw - 215px);
  top: 130px;
  height: 215px;
  opacity: 0.3;
}

.swt-db-selector-wrapper {
  color: rgb(48, 47, 47);
  background-color: #12365b;
  border-radius: 0px 0px 8px 8px;
  position: absolute;
  right: 5px;
  top: 80px;
  height: 30px;
  width: auto;
  padding-right: 5px;
  padding-bottom: 5px;
  z-index: 15000;
  box-shadow: 0 7px 8px -6px black;
  -webkit-box-shadow: 0 7px 8px -6px black;
  border-bottom: 1px solid rgba(211, 211, 211, 0.356);
  border-left: 1px solid rgba(211, 211, 211, 0.356);
  border-right: 1px solid rgba(211, 211, 211, 0.356);
}

.swt-db-selector {
  /* position: absolute; */
  color: rgb(48, 47, 47);
  margin-top: 5px;
  margin-left: 10px;
  height: 22px;
  font-size: 12px;
  border-radius: 6px;
  border: solid 1px var(--dark-grey);
  background-color: #ffffff;
  width: auto;
  min-width: 200px;
  z-index: 15001;
  margin-bottom: 3px;
}

.swt-class-selector {
  background-color: transparent;
  border: none;
  font-size: 14px;
}
.swt-category-selector {
  background-color: transparent;
  border: none;
  font-size: 14px;
}
.swt-vehicle-selector {
  /* height: 30px; */
  font-size: 15px;
  border-radius: 6px;
  border: solid 1px var(--dark-grey);
  background-color: #ffffff;
  /* width: 329px;
  margin-bottom: 25px;
  margin-right: 25px; */
  text-indent: 10px;
}
.swt-vehicle-selector:hover {
  font-weight: bold;
  cursor: pointer;
}

.swt-app {
  font-family: "Arimo","Open Sans","HelveticaNeue","Helvetica Neue","Arial","sans-serif";
  color: var(--charcoal-grey);
  margin: 0px;
  /* coloring for stand alone sites */
  /* background: #2374ab; */
}

.swt-main {
  position: absolute;
  min-height: 535px;
  height: auto;
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin-top: 80px;
  /* 
  @TODO: This is a less than ideal workaround so that we don't need to adjust all of the products' CSS to the new menu bar. 
  The following lines should be uncommented once all products' CSS is overhauled. -NK 3/29/24
  margin-left: 64px;
  width: calc(100% - 64px); */
}

.swt-main-no-nav {
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#swt-main .swt-login .swt-auth {
  margin-top: 80px;
  height: 350px;
  width: 350px;
  background: transparent;
  text-align: center;
}

.swt-password-change .swt-auth,
.swt-password-reset-container .swt-auth {
  margin: auto;
  margin-top: 7rem;
  height: 350px;
  width: 350px;
  background: transparent;
}
.swt-password-change-wrapper #unauthenticated {
  margin-top: 0rem;
}

.swt-password-change .swt-button-wrapper {
  width: 300px;
  margin-top: 1rem;
}

.swt-password-change .swt-button-wrapper button {
  margin-left: 10px;
}

.swt-auth#pw-reset {
  margin-top: 19rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 17.5rem;
}

.swt-auth#pw-reset .swt-submit-button {
  margin-left: 35px;
  background-color: white;
}

.swt-password-reset-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 10rem;
}

.swt-admin-modal.swt-admin-display .swt-auth {
  margin-top: 35px;
  text-align: center;
}

.swt-credential-input-label {
  color: #000;
  text-align: center;
  margin: 5px;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.3px;
}

.swt-credential-input {
  left: 25px;
  width: 300px;
  height: 1.5rem;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid grey;
  text-align: center;
}

.swt-credential-msg {
  font-size: 12px;
  text-align: left;
}

.swt-submit-placeholder,
.swt-submit-button {
  width: 100px;
  height: 35px;
  font-size: 15px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  border-radius: 10px;
  border: 1px solid grey;
  background-color: white;
}

.swt-submit-placeholder {
  border: 1px solid rgb(187, 185, 185);
}
.swt-submit-placeholder #swt-submit-text {
  color: grey;
  margin: auto;
  text-align: center;
  margin: 8px;
}

.swt-submit-button:hover, .swt-save-button:hover {
  background-color: rgba(63, 129, 228, 0.096);
  border: 1px soli grey;
  cursor:pointer;
}

.swt-save-button {
  background-color: white;
  width: auto;
  height: 35px;
  font-size: 15px;
  margin-top: 5px;
  margin-left: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
  border: 1px solid grey;
}
.swt-dashboard-header {
  position: fixed;
  z-index: 15000;
  width: 100%;
  text-align: left;
  color: white;
  box-shadow: 0 7px 8px -6px black;
  -webkit-box-shadow: 0 7px 8px -6px black;
  /* min-width: 1050px; */
}
.swt-dashboard-header #authenticated {
  background: #12365b;
  height: 100%;
  height: 80px;
  border-bottom: 1px solid rgba(211, 211, 211, 0.356);
}

.swt-dashboard-header #unauthenticated {
  background: #12365b;
  height: 100%;
  height: 100px;
}

.swt-dashboard-header #swt-company-name {
  position: absolute;
  top: 60px;
  padding-left: 175px;
  font-style: normal;
  color: white;
  font-size: 11pt;
}

.swt-dashboard-header #swt-company-name-LI {
  position: absolute;
  top: 52px;
  left: 135px;
  padding-left: 16px;
  font-style: normal;
  color: white;
  font-size: 10pt;
}

.swt-dashboard-header #swt-user-management {
  cursor: pointer;
  padding-bottom: 8px;
  position: absolute;
  top: 0px;
  right: 0;
}

#swt-cdr-management:hover {
  cursor: pointer;
  opacity: 0.85;
}

#swt-user-management:hover {
  opacity: 0.85;
  cursor: pointer;
}


.swt-reset-password {
  position: sticky;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.swt-reset-password:hover {
  position: sticky;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: rgb(30, 30, 155);
}

.swt-reset-password-txt {
  padding-top: 5rem;
  text-align: center;
}

.swt-conform-message {
  color: red;
  font-size: 12px;
  margin-left: 5%;
  width: 280px;
  text-align: center;
}

#swt-password-render-icon {
  height: 16px;
  margin: 0px;
}
button.swt-password-render-button {
  margin: 0px;
  border: none;
  /* border-radius: 8px; */
  width: 30px;
  height: 20px;
  margin-left: -37px;
  background-color: white;
}

.swt-loading {
  width: 20vw;
  height: 20vh;
  margin: auto;
  padding: 5rem;
  max-width: 300px;
  max-height: 300px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none; /*Removes arrow buttons on inputs for webkit-based browsers*/
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Remove arrows on Firefox */
}

.nav-menu,.nav-menu-minimized {
  position: fixed;
  top: 80px; 
  left: 0px;
  height: 100%;
  transition: width 0.15s;
  z-index: 14000;
}

.nav-menu {
  width: 215px;
}

.nav-menu-minimized {
  width: 64px;
}