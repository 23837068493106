:root {
  --ezev-dark-grey: #2f2f30;
  --ezev-bluish: #259cc4;
  --ezev-charcoal-grey: #47484a;
  --ezev-deep-aqua: #0b6787;
  --ezev-battleship-grey: #74787d;
  --ezev-light-grey-blue: #b6bcc1;
  --ezev-light-sky-blue: #d8e3ed;
  --ezev-dark-turquoise: #035e5b;
  --ezev-blue-green: #13908b;
  --ezev-dark-grey-two: #262627;
  --ezev-bluey-grey: #979a9e;
  --ezev-muted-blue: #72afc4;
  --ezev-light-grey: #f5f5f5;
  --disabled-grey: #d3d3d3;
  --white: #ffffff;
  --black: #000000;
}
.admin-wrapper {
  width: 90%;
  margin-left: 5%;
}


.swt-admin-tab-wrapper {
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-left: 2.7rem;
  padding: 3rem 2.5rem;
  min-height: 24rem;
  justify-content: center;
  overflow: auto;
}

.swt-admin-console-ttl {
  margin-top: 1.2rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  color: var(--ezev-bluey-grey);
}




.swt-admin-navitem:hover {
  color: var(--muted-blue);
}

.swt-submenu {
  position: sticky;
  bottom: 0px;
  display: flex;
  padding: 5px 5px 4px 5px;
  padding-left: 44%;
  padding-bottom: 10px;
  margin: 2px;
  background-color: white;
}

.swt-admin-submenu-btn {
  /* width: 80px; */
  padding: 6px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  height: 17px;
}

.swt-admin-table {
  border: 1px solid rgb(0, 0, 0);
  background-color: white;
  border-radius: 5px;
  border-collapse: collapse;
  border-color: grey;
  font-size: 0.9rem;
  width: 100%;
}
.swt-admin-table td {
  border: 1px solid rgb(221, 219, 219);
}

.swt-admin-button-wrapper a {
  min-width: 100px;
  width: auto;
  max-width: 250px;
  height: 15px;
  padding: 3px 8px 5px 8px;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border: 0.7px solid #979a9e;
  border: 0.7px solid rgb(116, 120, 125);
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  text-decoration: none;
  box-shadow: 5px 0px 3px grey;
  cursor: pointer;
  /* margin: 0.5rem 0.25rem 0.5rem 0.25rem; */
  /* margin: auto; */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  color: grey;
  font-weight: bold;
}

.swt-admin-button-wrapper .active {
  color: white;
  font-weight: bold;
  background: rgb(180, 180, 180);
  /* box-shadow: inset 0px 0px 5px #c1c1c1; */
}

.swt-admin-button-wrapper {
  width: auto;
  max-width: 80%;
  margin: auto;
  height: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 0.19fr));
  grid-template-rows: 100%;
  /* padding: 1rem; */
  /* justify-content: center;  */
  /* border: 1px solid rgb(219, 217, 217);
  border-radius: .25rem; */
}

.swt-admin-table-header {
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: sticky;
  border-bottom: 1px solid grey;
  background-color: white;
  cursor: pointer;
}

.swt-admin-table-header-selected{
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: sticky;
  top: -0.1rem;
  border-bottom: 1px solid grey;
  background-color: lightblue;
  cursor: pointer;
}

.swt-admin-table-row {
  height: 25px;
}

.swt-admin-table-row:nth-child(odd) {
  background-color: var(--ezev-light-grey);
}
.swt-admin-tablecell {
  padding-left: 5px;
  font-size: 15px;
}
.swt-admin-static-cell{
  font-size: 13px;
  font-family: Arial;
}
.swt-admin-static-cell:hover {
  cursor: default;
}
.swt-admin-tablecell#id {
  position: sticky;
  left: 0;
  z-index: 22;
  background-color: white;
}

.swt-admin-table-row:nth-child(odd) .swt-admin-tablecell#id {
  position: sticky;
  left: 0;
  z-index: 22;
  background-color: var(--ezev-light-grey);
}

.swt-admin-tablecell#vin {
  font-size: 0.9rem;
}
.swt-admin-tablecell-small {
  padding-left: 5px;
}

.swt-admin-tablecell-bool {
  text-align: center;
  cursor: pointer;
}
.swt-admin-tablecell-product:hover,
.swt-admin-tablecell-bool:hover {
  background-color: rgba(25, 124, 238, 0.192);
}

.swt-admin-tablecell-bool-noedit {
  width: 30px;
  padding: 15px;
  text-align: center;
}

.swt-admin-tablecell-date {
  width: 250px;
  text-align: center;
}

.swt-admin-tablecell-product {
  margin-left: 200px;
  text-align: center;
  color: black;
}

.swt-admin-modal {
  z-index: 24;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.swt-admin-modal-close-btn {
  position: fixed;
  top: 6px;
  right: 8px;
  border-width: 1px;
  border-radius: 6px;
  border: 0.5px solid grey;
  background-color: white;
  color: grey;
  font-size: 11px;
  cursor: pointer;
}
.swt-admin-modal-database,
.swt-admin-modal-int,
.swt-admin-modal-password,
.swt-admin-modal-groups,
.swt-admin-modal-main {
  position: fixed;
  background: white;
  border-radius: 0.25rem;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
}

.swt-admin-modal-main {
  width: 500px;
  height: auto;
  overflow: scroll;
  padding: 15px 30px 30px 30px;
}

.swt-admin-modal-int {
  width: 400px;
  height: 180px;
}

.swt-admin-modal-int .swt-admin-db-selector {
  width: 300px;
  height: 45px;
  color: black;
  text-align: center;
  margin: 5px;
  position: fixed;
  left: 45px;
  top: 51px;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.3px;
}
.swt-admin-modal-int .swt-submit-placeholder,
.swt-admin-modal-int .swt-submit-button {
  width: 100px;
  height: 35px;
  top: 25px;
  font-size: 15px;
  margin-top: 100px;
  margin-left: 149px;
  border-radius: 10px;
  position: fixed;
  background-color: white;
}

.swt-admin-display .swt-credential-input {
  border-radius: 0.25rem;
}
.swt-admin-modal-int .swt-credential-input-label {
  width: 300px;
  position: absolute;
  color: grey;
  text-align: center;
  margin: 5px;
  padding-top: 9px;
  left: 38px;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.3px;
}

.swt-admin-modal-password {
  justify-content: center;
  text-align: center;
  width: 400px;
  height: 275px;
}

.swt-admin-modal-groups {
  justify-content: center;
  text-align: center;
  width: 600px;
  max-height: 575px;
  margin-top: 0rem;
  overflow: hidden;
}

.swt-admin-modal-groups .admin-groups-table {
  max-height: 23rem;
  overflow: scroll;
  border: 1px solid grey;
  border-radius: 4px;
  margin-left: 5%;
  margin-right: 5%;
}

.swt-admin-modal-groups .admin-groups-list {
  text-align: left;
  border-collapse: seperate;
  border-spacing: 0.5px;
  justify-content: center;
  max-height: 4rem;
  overflow: scroll;
}

.swt-admin-modal-groups .admin-groups-list th {
  height: 15px;
  border-bottom: 1px solid grey;
  padding: 9px;
}
.swt-admin-modal-groups .admin-groups-list th#group {
  width: 30rem;
  position: sticky;
  top: 0;
  background-color: white;
}
.swt-admin-modal-groups .admin-groups-list th#selected {
  width: 5rem;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 100;
}

.admin-group-name {
  padding-left: 10px;
}

.swt-admin-modal-groups .admin-groups-list th#selected {
  padding-right: 10px;
}
.swt-admin-modal-groups .admin-groups-list th#group {
  padding-left: 10px;
}

.swt-admin-modal-groups .admin-groups-list .admin-group-toggle {
  justify-content: right;
  text-align: right;
  padding-right: 40px;
}

.swt-group-modal-title {
  padding: 20px;
}

.swt-group-modal-title #selectedUser {
  text-decoration: underline;
  font-weight: 900;
}

.swt-admin-modal-groups .admin-groups-list tr:nth-child(odd) td {
  background-color: rgba(128, 128, 128, 0.219);
}

.swt-admin-modal-database {
  justify-content: center;
  text-align: center;
  width: 800px;
  height: 550px;
}

.admin-groups-cancel,
.admin-groups-save {
  margin: 10px;
}

.swt-admin-modal-database .swt-admin-multiselect {
  width: 90%;
  margin-left: 5%;
  margin-top: 4%;
  margin-bottom: 10%;
}

.swt-admin-modal-database .swt-submit-button {
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 0px;
  background-color: white;
}

label#swt-password-form-label {
  margin-top: 20px;
  color: rgb(65, 63, 63);
  font-size: 14px;
}

h2#swt-database-form-title {
  padding-top: 15px;
  color: rgb(65, 63, 63);
  font-size: 20px;
}

.swt-admin-modal-password h3#swt-password-form-title {
  margin-top: 30px;
  padding-bottom: 10px;
  font-size: 18px;
  padding: 5px;
}


.swt-admin-display {
  display: block;
}

.swt-admin-db-selector {
  width: 300px;
  height: 1.5rem;
  color: black;
  text-align: center;
  margin: 5px;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.3px;
}

.swt-delete-button,
.swt-password-button {
  width: 100%;
  min-height: 30px;
  border-radius: 2px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.11);
  border: 1px solid grey;
  background-color: white;
  border-radius: 0.25rem;
}

.swt-delete-button {
  color: red;
  font-size: 11px;
  text-decoration: underline;
}

.swt-password-button #swt-admin-button-text {
  font-size: 10px;
  font-weight: 500;
}

.swt-admin-modal-password p.swt-conform-message {
  margin-left: 55px;
}

.swt-admin-modal-password .swt-submit-button {
  margin-top: 25px;
  margin-left: 0px;
  background-color: white;
}
.swt-seasonal-rates,
.swt-assumptions-wrapper,
.swt-admin-user-wrapper,
.swt-admin-account-table-wrapper,
.swt-admin-accounts-wrapper {
  overflow: scroll;
  max-height: 45rem;
  overflow: scroll;
  padding-left: 2.5%;
  width: 95%;
  height: 90%;
}

.swt-admin-account-table-wrapper {
  height: 39rem;
}

.swt-assumptions-wrapper h3 {
  text-align: center;
}

#admin-accounts-wrapper-text,
#admin-user-wrapper-text {
  text-align: center;
  color: rgb(100, 100, 100);
  position: sticky;
  top: 0rem;
  margin: 2px;
  background-color: white;
}

.swt-settings-form {
  position: relative;
  margin-bottom: 0.5rem;
}

.swt-settings-title {
  color: rgb(100, 100, 100);
  padding-top: 10px;
}

.swt-settings-type-wrapper {
  text-align: center;
  padding-top: 10px;
}

.swt-settings-type-wrapper .swt-button-1.selected {
  background: rgba(180, 180, 180, 0.6);
  box-shadow: 2px 2px 2px rgb(180, 180, 180);
}

.swt-settings-type-wrapper .swt-button-1 {
  margin: 0 10px;
  border-radius: 6px;
}

.swt-button-1 {
  padding: 4px 12px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  color: rgb(53, 52, 52);
}

.swt-button-1.landing {
  margin-left: 41%;
  height: 32px;
}
.swt-setting-label {
  font-weight: 500;
  font-size: 0.8rem;
}
.swt-setting-input {
  border-radius: 4px;
  border: 1px solid grey;
  width: 11rem;
}

.swt-setting-label#baseline {
  color: red;
}

.ezev-settings-form-error-list {
  color: var(--ionev-red);
  font-weight: 500;
  font-size: 0.8rem;
}

.swt-setting-input option {
  border-radius: 4px;
  border: 1px solid grey;
  width: 20rem;
}

.swt-input-wrapper {
  margin: auto;
  background-color: white;
  margin: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
  border-radius: 4px;
  padding-top: 10px;
  min-width: 30rem;
  min-height: 386px;
  height: auto;
  border: 1px solid grey;
  display: grid;
  grid-template-areas: "a a";
  grid-gap: 2px;
  grid-auto-columns: 50%;
}

.settings-item {
  margin-left: 30%;
}

.swt-admin-vclManager {
  /* border: 1px solid rgba(119, 119, 119, 0.247); */
  /* border-radius: 8px; */
  margin-top: 2px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-left: 2.7rem;
  margin-right: 3%;
  /* box-shadow: inset 0px 0px 1px 1px rgb(155, 155, 155); */
  max-height: 45rem;
  width: 90%;
  overflow: hidden;
  height: 90%;
  position: relative;
}

.admin-vehicles-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.swt-admin-vclManager .swt-table-wrpr {
  border: 1px solid grey;
  margin: 12px;
  border-radius: 0.25rem;
  max-height: 36rem;
  overflow: scroll;
  width: auto;
}

.swt-button-wrapper {
  justify-content: center;
  text-align: center;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.swt-admin-vcl-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.swt-admin-vcl-table tr:nth-child(odd) .swt-admin-input,
.swt-admin-vcl-table tr:nth-child(odd) .swt-admin-input select {
  background-color: var(--ezev-light-grey);
}

.swt-admin-vcl-table th {
  font-size: 13px;
  border: 1px solid grey;
}
.swt-admin-vcl-table td {
  border: 1px solid grey;
}
.swt-admin-vcl-table .swt-class-selector,
.swt-admin-vcl-table {
  font-size: 13px;
  background-color: white;
  color: rgb(53, 53, 53);
  border: 1px solid white;
}

.swt-admin-input{
  border: 0px;
  background-color: transparent;
  width: 100%;
  text-overflow: ellipsis;
}

.swt-admin-vcl-table .swt-admin-table-header {
  padding-bottom: 10px;
  position: sticky;
  top: -0.1rem;
  border-bottom: 1px solid grey;
  background-color: white;
}

.admin-table-header {
  min-width: 90px;
  padding: 0 30px;
}
.admin-table-header-long {
  padding: 0 30px;
  min-width: 170px;
}

.swt-admin-vcl-loading {
  position: relative;
  width: 100%;
  text-align: center;
  height: 20rem;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.swt-new-token-wrpr {
  margin-top: 10rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.swt-new-token-wrpr .swt-credential-input-label,
.swt-admin-reset-token-txt {
  margin: 1rem;
  margin-bottom: 2rem;
}

.swt-new-token-wrpr .swt-submit-button {
  margin-left: 0%;
}

.swt-password-reset-wrpr {
  margin-top: 0rem;
}
.swt-seasonal-rates {
  margin-top: 0rem;
  position: relative;
}
.swt-seasonal-rates-table {
  width: 100%;
  height: 100%;
}

.swt-seasonal-rates-table .swt-admin-table-cell {
  font-size: 0.9rem;
}

.swt-seasonal-rates-table-head .swt-admin-table-header {
  top: 0rem;
}

.swt-seasonal-no-rates {
  margin: 2rem;
  border: 1px solid black;
  padding: 10px;
  text-align: center;
}
.swt-seasonal-delete,
.swt-seasonal-edit {
  width: 20px;
  height: 20px;
}
.swt-seasonal-dow-wrpr,
.swt-seasonality-time-wrapper,
.swt-seasonality-date-wrapper {
  border: 1px solid grey;
  padding: 5px;
  margin: 5px;
  border-radius: 0.25rem;
  text-align: center;
  background-color: white;
}

.swt-seasonal-dow-wrpr {
  display: grid;
  grid-template-rows: 35% 65%;
  text-align: center;
}

.swt-seasonal-dow-label {
  font-weight: regular;
}

.swt-seasonality-dow-options {
  display: grid;
  grid-template-columns: 14.3% 14.3% 14.3% 14.3% 14.3% 14.3% 14.3%;
  width: 75%;
  margin-left: 12.5%;
}
.swt-seasonal-dow {
  border: 1px solid grey;
  border-radius: 0.25rem;
  width: 35px;
  display: inline-block;
  margin-left: 3px;
  text-align: center;
  justify-content: center;
  padding-bottom: 3px;
}

.swt-seasonality-time-wrapper#disabled,
.swt-seasonality-time-wrapper#disabled .swt-seasonality-title,
.swt-seasonality-time-wrapper#disabled .swt-seasonality-time,
.swt-seasonality-time-wrapper#disabled .swt-seasonality-text,
.swt-seasonality-date-wrapper#disabled,
.swt-seasonality-date-wrapper#disabled .swt-seasonality-title,
.swt-seasonality-date-wrapper#disabled .swt-seasonality-date,
.swt-seasonal-dow-wrpr#disabled,
.swt-seasonal-dow-wrpr#disabled .swt-seasonal-dow,
.swt-seasonal-dow-wrpr#disabled .swt-seasonal-dow-label,
.swt-seasonal-dow-wrpr#disabled label {
  color: rgb(192, 191, 191);
  border-color: rgb(192, 191, 191);
  background-color: rgb(238, 235, 235);
}

.swt-seasonal-save-btn {
  background-color: white;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-left: 41%;
  height: 2rem;
}
.swt-seasonality-time,
.swt-seasonality-date {
  display: inline-block;
}

.swt-seasonality-text {
  font-weight: bold;
  font-size: 12px;
  display: inline;
}

.swt-seasonal-label {
  font-size: 15px;
  width: 100%;
  padding: 0px 3px 0px 3px;
}

.swt-seasonal-rates .swt-admin-modal-main {
  height: 28rem;
}

.swt-seasonal-rate-title {
  margin-top: 1rem;
  margin-bottom: 0.7rem;
  text-align: center;
  font-size: 20px;
  color: grey;
}

.swt-seasonality-required-inputs {
  padding: 10px;
  padding-bottom: 0px;
  margin: 5px;
  border: 1.5px solid grey;
  text-align: center;
  justify-content: center;
  border-radius: 0.25rem;
  margin-bottom: 1.5rem;
}

.swt-seasonality-required-inputs input {
  border-radius: 0.25rem;
  border: 1px solid grey;
  text-align: center;
}

.swt-required-inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "labelL labelR"
    "inputL inputR";
}

.swt-seasonal-label#name {
  grid-area: labelL;
}
.swt-seasonal-label#rate {
  grid-area: labelR;
}
.swt-seasonal-name {
  grid-area: inputL;
}

.swt-seasonal-rate {
  grid-area: inputR;
}

.swt-seasonal-name,
.swt-seasonal-rate {
  width: 80%;
  margin-left: 10%;
}

.swt-seasonality-enable-sections {
  background-color: rgb(238, 235, 235);
  border-radius: 0.25rem;
  margin: 5px;
  padding: 5px;
  text-align: center;
  display: grid;
  grid-template-rows: 50% 50%;
}

.swt-seasonality-toggle-wrapper {
  text-align: center;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  font-size: 12px;
  padding-bottom: 4px;
}

.swt-seasonality-toggle-wrapper input {
  margin-left: 8px;
}

.swt-seasonal-add-button {
  padding: 6px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  height: 32px;
  bottom: 0;
  margin: 1.5rem 0rem 0.5rem 39%;
  color: rgb(53, 52, 52);
}

.swt-seasonal-dropdown input {
  border: 1px solid grey;
}

.swt-seasonality-enable-options-wrapper {
  background-color: rgb(238, 235, 235);
  border-radius: 0.25rem;
  margin: 5px;
  padding: 5px;
}


.swt-vcl-candidate-wrapper {
  justify-content: center;
  text-align: center;
}

.swt-vcl-candidate-wrapper h3 {
  margin: 0px;
}
.swt-vcl-candidate-wrapper .candidate-table {
  overflow: scroll;
  margin: 0.5rem 1rem 1rem 1rem;
  border: 1px solid grey;
  border-radius: 0.25rem;
}

.swt-vcl-candidate-wrapper .candidate-table {
  overflow: scroll;
  margin: 0rem 1rem 1rem 1rem;
  border: 1px solid grey;
  border-top: none;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  max-height: 35rem;
  min-height: 14rem;
}

.swt-vcl-candidate-wrapper .swt-table-search {
  background-color: white;
  color: grey;
  border: 1px solid grey;
  margin: 3rem 1rem 0rem 1rem;
  padding-bottom: 0.25rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.swt-vcl-candidate-wrapper button#save {
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 128, 0, 0.74);
  padding: 5px;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  width: 7rem;
}

.swt-vcl-candidate-wrapper button#save:disabled {
  background-color: var(--ezev-light-grey);
}

.swt-vcl-candidate-wrapper button#add {
  text-align: center;
  justify-content: center;
  background-color: white;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  width: 10rem;
  margin-bottom: 0rem;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.swt-vcl-candidate-wrapper .swt-admin-table {
  border-top: none;
}

.swt-vcl-candidate-wrapper .swt-admin-table-header {
  margin-bottom: 25px;
  padding-bottom: 10px;
  position: sticky;
  top: 0rem;
  border-bottom: 1px solid grey;
  background-color: white;
}

.swt-vcl-candidate-wrapper .swt-admin-table-header#Id {
  left: 0rem;
  top: 0rem;
  z-index: 23;
}

.swt-vcl-candidate-wrapper
  .candidate-table
  .swt-admin-table
  .swt-admin-table-row#inactive
  td
  input,
.swt-vcl-candidate-wrapper
  .candidate-table
  .swt-admin-table
  .swt-admin-table-row#inactive
  td
  select {
  color: rgb(185, 184, 184);
}

.swt-vcl-candidate-wrapper
  .candidate-table
  .swt-admin-table
  .swt-admin-table-row
  td
  input,
.swt-vcl-candidate-wrapper
  .candidate-table
  .swt-admin-table
  .swt-admin-table-row
  td
  select {
  border: none;
}

.add-candidate-form {
  text-align: left;
  border: 1px solid grey;
  border-radius: 0.25rem;
  margin: 1rem;
}
.add-candidate-form label {
  margin: 0.25rem;
  margin-left: 2rem;
  width: 5rem;
}
.add-candidate-form select,
.add-candidate-form input {
  margin-top: 0.25rem;
}

.add-candidate-form input#submit-button {
  text-align: center;
  margin-top: 1rem;
  margin-left: 40%;
  background: white;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.swt-admin-vclManager {
  position: absolute;
  top: 1.5rem;
  right: -1rem;
}

.swt-admin-vclManager .swt-table-search {
  background-color: white;
  border: none;
}

.swt-admin-table-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swt-admin-input:hover {
  background-color: rgba(63, 129, 228, 0.096);
  border: 1px soli grey;
  cursor: pointer;
}
.swt-admin-vcl-table tr:nth-child(odd) .swt-admin-input:hover {
  background-color: rgba(63, 129, 228, 0.096);
  border: 1px soli grey;
  cursor: pointer;
} 

.swt-admin-table-input {
  accent-color: #259cc4;
  height: 18px;
  width: 18px;
  background-color: white;
}

.swt-map-btn {
  color: #0078a8;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0;
  padding-bottom: 0;
}