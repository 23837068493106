@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,700;1,400&display=swap');

 /* Convert to styled components when time permits / where possible */
.ezio-date-selector {
    width: 105px;
    height: 45px;
    background-color: var(--white-two);
    padding: 0;
    padding-left: 3px;;
    margin: 0 11px 0 0;
    border: 2px solid #F5F5F5;
    color: #4A4A4A;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-indent: 7px;
    border-radius: 5px;
  }

  .ezio-map-container {
    height: 560px;
    margin-top: 15px;
    z-index: 0;
  }